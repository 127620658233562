import React from 'react';

import { Card, Form, FormRow, Input } from '@neslotech/eventhub-ui-kit';

import './contact-details.scss';

const ContactDetails = ({ organisation }) => (
  <Card>
    <section className="contact-details">
      <section className="contact-details__icon">
        <img src={organisation.image} alt={organisation.name} />
      </section>
      <section className="contact-details__form">
        <header>
          <h4>{organisation.name}</h4>
        </header>
        <Form>
          <FormRow fluid>
            <Input
              placeholder="Physical Address"
              label="Physical Address"
              value={organisation.physical_address}
              disabled
            />
            <Input
              placeholder="+27 123456789"
              label="Phone Number"
              value={organisation.phone_number}
              disabled
            />
          </FormRow>
          <FormRow>
            <Input placeholder="Suburb" value={organisation.suburb} disabled />
          </FormRow>
          <FormRow>
            <Input placeholder="Postal Code" value={organisation.postal_code} disabled />
          </FormRow>
        </Form>
      </section>
    </section>
  </Card>
);

export default ContactDetails;
