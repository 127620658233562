export const LOAD_PAYMENTS = '[ENTRY_STORE] Load payments';
export const SET_PAYMENTS = '[ENTRY_STORE] Set payments';
export const loadPayments = (onComplete) => ({
  type: LOAD_PAYMENTS,
  onComplete
});

export const LOAD_PAYMENT = '[ENTRY_STORE] Load payment';
export const SET_PAYMENT = '[ENTRY_STORE] Set payment';
export const loadPayment = (id, onComplete) => ({
  type: LOAD_PAYMENT,
  id,
  onComplete
});

export const CONFIRM_PAYMENT = '[ENTRY_STORE] Confirm payment';
export const confirmPayment = (id, payload) => ({
  type: CONFIRM_PAYMENT,
  id,
  payload
});
