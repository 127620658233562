import React, { createRef, useMemo } from 'react';

import {
  Button,
  ContextCard,
  ContextInfoList,
  DetailsSection,
  FileUploadGroup,
  Form,
  FormRow,
  Input,
  PAYMENT_REQUIRED_STATUS,
  Table,
  TextArea,
  toBase64
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import { ReactComponent as ChainIcon } from '../../../icons/chain-icon.svg';

import './payment-details.scss';

const EntrantDetails = ({ payment }) => (
  <DetailsSection
    title={payment.created_by.full_name}
    subtitle={`Reference Number: ${payment.reference_number}`}
  >
    <Form>
      <FormRow fluidSpaced>
        <Input
          disabled
          label="Phone Number"
          placeholder="Phone Number"
          value={payment.created_by.phone_number}
        />
        <Input
          disabled
          label="Email Address"
          placeholder="Email Address"
          value={payment.created_by.email}
        />
      </FormRow>
    </Form>
  </DetailsSection>
);

const TransactionReference = ({ form, handleChange }) => (
  <Form>
    <FormRow singleRow>
      <Input
        name="reference"
        label="Reference Number"
        placeholder="Reference Number"
        value={form?.reference}
        error={form?.messages?.reference}
        onChange={handleChange}
      />
    </FormRow>
  </Form>
);

const paymentDetailsMessage =
  'Please ensure the amount owed matches the amount the participant paid on the proof of payment. ' +
  'If there are any issues please contact the participant directly.';

const paymentDetailsHeaders = [
  {
    Header: 'Participant',
    accessor: 'participant'
  },
  {
    Header: 'Price',
    accessor: 'price'
  }
];

const rules = {
  validates: {
    reference: ['isPresent'],
    proofOfPayment: ['isPresent']
  }
};

const formify = ({ proof_of_payment }) => ({
  proofOfPayment: proof_of_payment
});

const serverify = (form) => ({
  transaction_reference: form.reference,
  proof_of_payment: form.proofOfPayment,
  note: form.note
});

const PaymentDetails = ({ payment, onConfirm }) => {
  const [form, setForm] = useFormState(formify(payment), rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const fileRef = createRef();

  const paymentDetailsData = useMemo(() => {
    return payment.pricing_model.entrant_pricing_list.map((pricing) => ({
      participant: pricing.entrant.full_name,
      price: pricing.subtotal_cost
    }));
  }, [payment.pricing_model]);

  const handleFileChange = async (file) => {
    const data = await toBase64(file);
    handleChange({ proofOfPayment: data });
  };

  const handleConfirm = () => {
    const payload = serverify(form);
    onConfirm(payload);
  };

  const uploadDescription = (
    <section className="payment-details__upload">
      <p>Upload the proof of payment</p>
      <Button label="Select file" onClick={() => fileRef.current.click()} />
    </section>
  );

  return (
    <article className="payment-details">
      <section className="payment-details__context">
        <ContextCard
          image={<img src={payment.image} alt={payment.name} />}
          context={{ status: payment.type }}
        >
          <ContextInfoList term="Name" value={payment.name} />
        </ContextCard>
      </section>
      <section className="payment-details__details">
        <EntrantDetails payment={payment} />
        <DetailsSection title="Payment Details" subtitle={paymentDetailsMessage}>
          <Table cols={paymentDetailsHeaders} rowData={paymentDetailsData} />
          <footer className="payment-details__total-due">
            <CostBreakdown pricing={payment.pricing_model} />
          </footer>
        </DetailsSection>
        <DetailsSection
          title="Transaction Reference"
          subtitle="Please enter the bank provided transaction reference number"
        >
          <TransactionReference form={form} handleChange={handleChange} />
        </DetailsSection>
        {form?.proofOfPayment && (
          <DetailsSection title="Attached Files">
            <article className="payment-details__attached-file">
              <section className="payment-details__attached-file-details">
                <ChainIcon />
                <p>Proof of Payment.pdf</p>
              </section>
              <a download="proofOfPayment.pdf" href={form?.proofOfPayment}>
                Download
              </a>
            </article>
          </DetailsSection>
        )}
        {!form?.proofOfPayment && (
          <DetailsSection
            title="Upload Files"
            subtitle="Upload the participants proof of payment to be able to approve this payment."
          >
            <FileUploadGroup
              fileRef={fileRef}
              label=""
              description={form?.proofOfPayment ? 'Upload the proof of payment' : uploadDescription}
              onChange={handleFileChange}
              fileTypes={['application/pdf']}
            />
          </DetailsSection>
        )}
        <DetailsSection title="Leave a Note">
          <TextArea
            name="note"
            boxed
            shadowed
            placeholder="Leave a Note"
            value={form?.note}
            onChange={handleChange}
          />
        </DetailsSection>
        {payment.status === PAYMENT_REQUIRED_STATUS && (
          <footer className="payment-details__actions">
            <Button
              longText
              disabled={!form?.valid}
              label="Confirm Payment"
              onClick={handleConfirm}
            />
          </footer>
        )}
      </section>
    </article>
  );
};

const CostBreakdown = ({ pricing }) => (
  <ul>
    <li>
      <span>Subtotal</span>
      <p>{pricing?.subtotal_cost}</p>
    </li>
    <li>
      <span>VAT</span>
      <p>{pricing?.vat_amount}</p>
    </li>
    <li>
      <span>Total</span>
      <p>{pricing?.total_cost}</p>
    </li>
  </ul>
);

export default PaymentDetails;
