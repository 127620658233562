import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST } from './index';

const getSportsEndpoint = () => `${API_HOST}/sports`;
export const getLoadSportsRequest = () => [
  getSportsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getAddSportRequest = (payload) => [
  getSportsEndpoint(),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getSportEndpoint = (id) => `${getSportsEndpoint()}/${id}`;
export const getLoadSportRequest = (id) => [
  getSportEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateSportRequest = (id, payload) => [
  getSportEndpoint(id),
  getHttpPutOptions(payload, getAuthHeaders())
];

export const getRemoveSportRequest = (id) => [
  getSportEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
