import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { noOp, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAddSportRequest,
  getLoadSportRequest,
  getLoadSportsRequest,
  getRemoveSportRequest,
  getUpdateSportRequest
} from '../../tools/api/sport.endpoints';

import { addSystemNotice } from '../../actions/system/system.actions';
import {
  ADD_SPORT,
  LOAD_SPORT,
  LOAD_SPORTS,
  loadSports,
  REMOVE_SPORT,
  SET_SPORT,
  SET_SPORTS,
  UPDATE_SPORT
} from '../../actions/sport/sport.actions';

export function* performLoadSports({ onComplete = noOp }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadSportsRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set sports on the state
    yield put({ type: SET_SPORTS, sports: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to load sports.', SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadSportsRequest() {
  yield takeLatest(LOAD_SPORTS, performLoadSports);
}

export function* performRemoveSport({ id, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveSportRequest(id);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('The sport has been removed.', SNACK_SUCCESS));

    // reload sports
    yield put(loadSports());

    // navigate to sports list page
    yield call(navigate, '/sports');
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to remove the sport.', SNACK_CRITICAL)
    );
  }
}

export function* watchForRemoveSportRequest() {
  yield takeLatest(REMOVE_SPORT, performRemoveSport);
}

export function* performAddSport({ payload, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getAddSportRequest(payload);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(`${payload.name} has been added.`, SNACK_SUCCESS));

    // reload sports
    yield put(loadSports());

    // load created sport
    yield call(navigate, `${data.id}`);
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to add the sport.', SNACK_CRITICAL));
  }
}

export function* watchForAddSportRequest() {
  yield takeLatest(ADD_SPORT, performAddSport);
}

export function* performLoadSport({ id, onComplete = noOp }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadSportRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set sport on the state
    yield put({ type: SET_SPORT, sport: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to load sport.', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadSportRequest() {
  yield takeLatest(LOAD_SPORT, performLoadSport);
}

export function* performUpdateSport({ id, payload, navigate, onComplete = noOp }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getUpdateSportRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(`${data.name} has been saved successfully.`, SNACK_SUCCESS));

    // set sport on the state
    yield put({ type: SET_SPORT, sport: data });

    // route to sport list page
    yield call(navigate, '/sports');
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to update sport.', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateSportRequest() {
  yield takeLatest(UPDATE_SPORT, performUpdateSport);
}

export default function* sportSaga() {
  yield all([
    watchForLoadSportsRequest(),
    watchForRemoveSportRequest(),
    watchForAddSportRequest(),
    watchForLoadSportRequest(),
    watchForUpdateSportRequest()
  ]);
}
