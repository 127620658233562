import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { loadSport, updateSport } from '../../../../actions/sport/sport.actions';

import AdminLayout from '../../../../components/layout/admin/AdminLayout';
import SportDetails from '../../../../components/sports/details/SportDetails';
import RemoveSportContainer from '../remove/RemoveSport.container';

const SportContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const sport = useSelector(({ sport_store }) => sport_store.sport);
  const stateLoading = useSelector(({ sport_store }) => sport_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(loadSport(id, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOnSave = (payload, onComplete) => {
    dispatch(updateSport(id, payload, navigate, onComplete));
  };

  const handleOnDelete = () => {
    setShowModal(true);
  };

  return (
    <AdminLayout gutterless>
      <PageLayout title="Sport Details" back fixed>
        {loading && <Loader filled />}
        {!loading && <SportDetails sport={sport} onSave={handleOnSave} onDelete={handleOnDelete} />}
        <RemoveSportContainer sport={sport} show={showModal} setShowModal={setShowModal} />
      </PageLayout>
    </AdminLayout>
  );
};

export default SportContainer;
