import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { noOp, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getInviteAdminsRequest,
  getLoadUserRequest,
  getLoadUsersRequest,
  getRemoveUserRequest
} from '../../tools/api/user.endpoints';

import { addSystemNotice } from '../../actions/system/system.actions';
import {
  INVITE_ADMINS,
  LOAD_USER,
  LOAD_USERS,
  loadUsers,
  REMOVE_USER,
  SET_USER,
  SET_USERS
} from '../../actions/user/user.actions';

export function* performLoadUsers({ onComplete = noOp }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadUsersRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set users on the state
    yield put({ type: SET_USERS, users: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to load users.', SNACK_CRITICAL));
  }

  yield call(onComplete);
}

export function* watchForLoadUsersRequest() {
  yield takeLatest(LOAD_USERS, performLoadUsers);
}

export function* performLoadUser({ id, onComplete = noOp }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadUserRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set user on the state
    yield put({ type: SET_USER, user: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to load user.', SNACK_CRITICAL));
  }

  yield call(onComplete);
}

export function* watchForLoadUserRequest() {
  yield takeLatest(LOAD_USER, performLoadUser);
}

export function* performInviteAdmins({ emails, onSuccess }) {
  try {
    for (let email of emails) {
      // get endpoint and http request options
      const [endpoint, requestOptions] = getInviteAdminsRequest(email);

      // make the request, no need to check the response
      yield call(axios, endpoint, requestOptions);
    }

    yield put(addSystemNotice('Successfully invited admin users.', SNACK_SUCCESS));

    // reload users
    yield put(loadUsers());

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to invite new admins.', SNACK_CRITICAL)
    );
  }
}

export function* watchForInviteAdminsRequest() {
  yield takeLatest(INVITE_ADMINS, performInviteAdmins);
}

export function* performRemoveUser({ id }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveUserRequest(id);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('The user has been removed.', SNACK_SUCCESS));

    // reload users
    yield put(loadUsers());
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to remove the user.', SNACK_CRITICAL)
    );
  }
}

export function* watchForRemoveUserRequest() {
  yield takeLatest(REMOVE_USER, performRemoveUser);
}

export default function* userSaga() {
  yield all([
    watchForLoadUsersRequest(),
    watchForLoadUserRequest(),
    watchForInviteAdminsRequest(),
    watchForRemoveUserRequest()
  ]);
}
