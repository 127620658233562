import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { requestChangeVerification } from '../../../../actions/verification/verification.actions';

import RequestChangeVerification from '../../../../components/verifications/request-change/RequestChangeVerification';

const RequestChangeVerificationContainer = ({ organisation, show, setShowModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClose = () => setShowModal(false);

  const handleConfirm = (payload) => {
    dispatch(requestChangeVerification(organisation.id, payload, navigate));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Request Change">
            <RequestChangeVerification onConfirmClick={handleConfirm} onCancelClick={onClose} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RequestChangeVerificationContainer;
