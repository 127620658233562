import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterElement, ProfileImage, searchElement, Table } from '@neslotech/eventhub-ui-kit';
import { capitalise } from '@neslotech/utils';

import {
  getCompetitionInitials,
  tableHeaders,
  tableModifiers
} from '../../containers/admin/competitions/competitions.helpers';

import { useFilters } from '../../hooks/useFilters';

import { ReactComponent as CloseCircleHollowIcon } from '../../icons/close-circle-hollow-icon.svg';

import RemoveCompetitionContainer from '../../containers/admin/competitions/remove/RemoveCompetition.container';

import './competitions-list.scss';

const formify = (competitions, onSelectRow, setCompetition, search, filters) =>
  competitions
    .filter(
      (competition) =>
        searchElement(competition.name, search) ||
        searchElement(competition.sport, search) ||
        searchElement(competition.competition_type, search)
    )
    .filter((competition) => filterElement(competition, filters))
    .map(
      ({ id, name, sport, competition_type, national, regulated, start_date, status, image }) => ({
        image: <ProfileImage imgSrc={image} initials={getCompetitionInitials({ name })} small />,
        name,
        sport,
        competitionType: competition_type,
        national: national ? 'National' : 'Regional',
        regulated: regulated ? 'Yes' : 'No',
        startDate: new Date(start_date).toLocaleDateString(),
        status: capitalise(status),
        onClick: () => onSelectRow(id),
        action: (
          <CloseCircleHollowIcon
            className="table__body-action--danger"
            onClick={(e) => {
              e.stopPropagation();
              setCompetition({ id, name });
            }}
          />
        )
      })
    );

const CompetitionsList = ({ competitions }) => {
  const navigate = useNavigate();

  const { search, filters, filtering, searching, setFilterDefaults } = useFilters();
  const [competitionToDelete, setCompetitionToDelete] = useState();

  const emptyMessage = useMemo(() => {
    return filtering || searching
      ? 'There are no competitions listed under that name.'
      : 'This table is empty. There are no competitions listed yet.';
  }, [filtering, searching]);

  useEffect(() => {
    setFilterDefaults({ status: '', regulated: '', national: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectRow = (id) => {
    navigate(`${id}`);
  };

  return (
    <section className="competitions-list">
      <Table
        cols={tableHeaders}
        columnModifiers={tableModifiers}
        rowData={formify(competitions, onSelectRow, setCompetitionToDelete, search, filters)}
        emptyTitle="No Competitions Found"
        emptySubtitle={emptyMessage}
      />
      <RemoveCompetitionContainer
        competition={competitionToDelete}
        show={competitionToDelete}
        setShowModal={setCompetitionToDelete}
      />
    </section>
  );
};

export default CompetitionsList;
