import React from 'react';

import { Card } from '@neslotech/eventhub-ui-kit';

import './details-section.scss';

const DetailsSection = ({ title, children }) => (
  <section className="details-section">
    <h5 className="details-section__title">{title}</h5>
    <Card>{children}</Card>
  </section>
);

export default DetailsSection;
