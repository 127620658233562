import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '../../providers/ProtectedRoute';

import DashboardContainer from './dashboard/Dashboard.container';
import UsersContainer from './users/Users.container';
import StatsContainer from './stats/Stats.container';
import EventsContainer from './events/Events.container';
import OrganisationsContainer from './organisations/Organisations.container';
import CompetitionsContainer from './competitions/Competitions.container';
import UserContainer from './users/view/User.container';
import OrganisationContainer from './organisations/view/Organisation.container';
import CompetitionContainer from './competitions/view/Competition.container';
import EventContainer from './events/view/Event.container';
import ProfileContainer from './profile/Profile.container';
import VerificationsContainer from './verifications/Verifications.container';
import VerificationContainer from './verifications/view/Verification.container';
import PaymentsContainer from './payments/Payments.container';
import PaymentContainer from './payments/details/Payment.container';
import SportsContainer from './sports/Sports.container';
import SportContainer from './sports/view/Sport.container';

const AdminContainer = () => (
  <Routes>
    <Route
      exact
      path="/dashboard"
      element={
        <ProtectedRoute>
          <DashboardContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/profile"
      element={
        <ProtectedRoute>
          <ProfileContainer />
        </ProtectedRoute>
      }
    />

    <Route
      exact
      path="/users"
      element={
        <ProtectedRoute>
          <UsersContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/users/:id"
      element={
        <ProtectedRoute>
          <UserContainer />
        </ProtectedRoute>
      }
    />

    <Route
      exact
      path="/events"
      element={
        <ProtectedRoute>
          <EventsContainer />
        </ProtectedRoute>
      }
    />

    <Route
      exact
      path="/events/:id"
      element={
        <ProtectedRoute>
          <EventContainer />
        </ProtectedRoute>
      }
    />

    <Route
      exact
      path="/organisations"
      element={
        <ProtectedRoute>
          <OrganisationsContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/organisations/:id"
      element={
        <ProtectedRoute>
          <OrganisationContainer />
        </ProtectedRoute>
      }
    />

    <Route
      exact
      path="/competitions"
      element={
        <ProtectedRoute>
          <CompetitionsContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/competitions/:id"
      element={
        <ProtectedRoute>
          <CompetitionContainer />
        </ProtectedRoute>
      }
    />

    <Route
      exact
      path="/verifications"
      element={
        <ProtectedRoute>
          <VerificationsContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/verifications/:id"
      element={
        <ProtectedRoute>
          <VerificationContainer />
        </ProtectedRoute>
      }
    />

    <Route
      exact
      path="/stats"
      element={
        <ProtectedRoute>
          <StatsContainer />
        </ProtectedRoute>
      }
    />

    <Route
      exact
      path="/payments"
      element={
        <ProtectedRoute>
          <PaymentsContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/payments/:id"
      element={
        <ProtectedRoute>
          <PaymentContainer />
        </ProtectedRoute>
      }
    />

    <Route
      exact
      path="/sports"
      element={
        <ProtectedRoute>
          <SportsContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/sports/:id"
      element={
        <ProtectedRoute>
          <SportContainer />
        </ProtectedRoute>
      }
    />
  </Routes>
);

export default AdminContainer;
