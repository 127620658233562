import {
  SET_ORGANISATION,
  SET_ORGANISATIONS
} from '../../actions/organisation/organisation.actions';

export const initialState = {
  organisations: [],
  organisation: undefined
};

export const organisationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANISATIONS:
      const { organisations } = action;
      return {
        organisations
      };
    case SET_ORGANISATION:
      const { organisation } = action;
      return {
        organisation
      };
    default:
      return state;
  }
};
