import { SET_VERIFICATIONS } from '../../actions/verification/verification.actions';

export const initialState = {
  verifications: []
};

export const verificationReducer = (state = initialState, action) => {
  if (action.type === SET_VERIFICATIONS) {
    const { verifications } = action;
    return {
      verifications
    };
  }

  return state;
};
