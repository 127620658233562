export const AUTHENTICATE = '[AUTH_STORE] Authenticate';
export const REMEMBER_ME = '[AUTH_STORE] Set remember me';
export const authenticate = (credentials, rememberMe, navigate, onComplete) => ({
  type: AUTHENTICATE,
  credentials,
  rememberMe,
  navigate,
  onComplete
});

export const REQUEST_PASSWORD_RESET = '[AUTH_STORE] Request password reset';
export const requestPasswordReset = (payload, navigate) => ({
  type: REQUEST_PASSWORD_RESET,
  payload,
  navigate
});

export const VALIDATE_RESET_TOKEN = '[AUTH_STORE] Validate reset token';
export const validateResetToken = (token, navigate) => ({
  type: VALIDATE_RESET_TOKEN,
  token,
  navigate
});

export const RESET_PASSWORD = '[AUTH_STORE] Reset password';
export const resetPassword = (token, payload, navigate) => ({
  type: RESET_PASSWORD,
  token,
  payload,
  navigate
});

export const VALIDATE_VERIFICATION_TOKEN = '[AUTH_STORE] Validate verification token';
export const validateVerificationToken = (token, navigate) => ({
  type: VALIDATE_VERIFICATION_TOKEN,
  token,
  navigate
});

export const VERIFY_EMAIL = '[AUTH_STORE] Verify email';
export const verifyEmail = (token, payload, navigate) => ({
  type: VERIFY_EMAIL,
  token,
  payload,
  navigate
});

export const COMPLETE_PROFILE = '[AUTH_STORE] Complete profile';
export const completeProfile = (token, payload, navigate) => ({
  type: COMPLETE_PROFILE,
  token,
  payload,
  navigate
});
