import { getHttpDeleteOptions, getHttpGetOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST } from './index';

const getOrganisationsEndpoint = () => `${API_HOST}/organisations`;
export const getLoadOrganisationsRequest = () => [
  getOrganisationsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getOrganisationEndpoint = (id) => `${API_HOST}/organisations/${id}`;
export const getLoadOrganisationRequest = (id) => [
  getOrganisationEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getRemoveOrganisationRequest = (id) => [
  getOrganisationEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
