export const LOAD_COMPETITIONS = '[COMPETITION_STORE] Load competitions';
export const SET_COMPETITIONS = '[COMPETITION_STORE] Set competitions';
export const loadCompetitions = (onComplete) => ({
  type: LOAD_COMPETITIONS,
  onComplete
});

export const LOAD_COMPETITION = '[COMPETITION_STORE] Load competition';
export const SET_COMPETITION = '[COMPETITION_STORE] Set competition';
export const loadCompetition = (id, onComplete) => ({
  type: LOAD_COMPETITION,
  id,
  onComplete
});

export const REMOVE_COMPETITION = '[COMPETITION_STORE] Remove competition';
export const removeCompetition = (id, navigate) => ({
  type: REMOVE_COMPETITION,
  id,
  navigate
});
