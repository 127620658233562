import React from 'react';

import { Card, Form, FormRow, Input, ProfileImage } from '@neslotech/eventhub-ui-kit';

import { getProfileInitials } from '../../../../containers/admin/users/users.helpers';

import './personal-details.scss';

const PersonalDetailsSection = ({ user }) => (
  <Card>
    <section className="personal-details">
      <section className="personal-details__icon">
        <ProfileImage imgSrc={user.image} initials={getProfileInitials(user)} />
      </section>
      <section className="personal-details__form">
        <header>
          <h4>Personal Details</h4>
        </header>
        <Form>
          <FormRow fluid>
            <Input placeholder="First Name" label="First Name" value={user.first_name} disabled />
            <Input placeholder="Last Name" label="Last Name" value={user.last_name} disabled />
          </FormRow>
          <FormRow>
            <Input
              placeholder="+27 123456789"
              label="Phone Number"
              value={user.phone_number}
              disabled
            />
          </FormRow>
        </Form>
      </section>
    </section>
  </Card>
);

export default PersonalDetailsSection;
