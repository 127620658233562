import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { rejectVerification } from '../../../../actions/verification/verification.actions';

import RejectVerification from '../../../../components/verifications/reject/RejectVerification';

const RejectVerificationContainer = ({ organisation, show, setShowModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClose = () => setShowModal(false);

  const handleConfirm = (payload) => {
    dispatch(rejectVerification(organisation.id, payload, navigate));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Reject Verification">
            <RejectVerification onConfirmClick={handleConfirm} onCancelClick={onClose} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RejectVerificationContainer;
