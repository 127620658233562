import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { loadUser } from '../../../../actions/user/user.actions';

import AdminLayout from '../../../../components/layout/admin/AdminLayout';
import UserDetails from '../../../../components/users/details/UserDetails';

const UserContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const user = useSelector(({ user_store }) => user_store.user);
  const stateLoading = useSelector(({ user_store }) => user_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadUser(id, () => setLoading(false)));
  }, [dispatch, id]);

  return (
    <AdminLayout gutterless>
      <PageLayout title="User Details" back fixed>
        {loading && <Loader filled />}
        {!loading && <UserDetails user={user} />}
      </PageLayout>
    </AdminLayout>
  );
};

export default UserContainer;
