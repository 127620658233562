import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getLoadDashboardRequest,
  getLoadProfileRequest,
  getUpdateProfileRequest
} from '../../tools/api/profile.endpoints';

import { addSystemNotice } from '../../actions/system/system.actions';
import {
  LOAD_DASHBOARD,
  LOAD_PROFILE,
  SET_DASHBOARD,
  SET_PROFILE,
  UPDATE_PROFILE
} from '../../actions/profile/profile.actions';

export function* performLoadProfile({ onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadProfileRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set profile on the state
    yield put({ type: SET_PROFILE, profile: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to load my profile.', SNACK_CRITICAL)
    );
  }

  if (onComplete) {
    yield call(onComplete);
  }
}

export function* watchForLoadProfileRequest() {
  yield takeLatest(LOAD_PROFILE, performLoadProfile);
}

export function* performUpdateProfile({ profile }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getUpdateProfileRequest(profile);

    // make the request, retrieve the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Your profile has been updated.', SNACK_SUCCESS));

    // set profile on the state
    yield put({ type: SET_PROFILE, profile: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to update my profile.', SNACK_CRITICAL)
    );
  }
}

export function* watchForUpdateProfileRequest() {
  yield takeLatest(UPDATE_PROFILE, performUpdateProfile);
}

export function* performLoadDashboard({ onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadDashboardRequest();

    // make the request, retrieve the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set profile on the state
    yield put({ type: SET_DASHBOARD, dashboard: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to load dashboard data.', SNACK_CRITICAL)
    );
  }

  yield call(onComplete);
}

export function* watchForLoadDashboardRequest() {
  yield takeLatest(LOAD_DASHBOARD, performLoadDashboard);
}

export default function* profileSaga() {
  yield all([
    watchForLoadProfileRequest(),
    watchForUpdateProfileRequest(),
    watchForLoadDashboardRequest()
  ]);
}
