import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterElement, ProfileImage, searchElement, Table } from '@neslotech/eventhub-ui-kit';
import { capitalise } from '@neslotech/utils';

import {
  getOrganisationInitials,
  tableHeaders,
  tableModifiers
} from '../../containers/admin/organisations/organisations.helpers';

import { useFilters } from '../../hooks/useFilters';

import { ReactComponent as CloseCircleHollowIcon } from '../../icons/close-circle-hollow-icon.svg';

import RemoveOrganisationContainer from '../../containers/admin/organisations/remove/RemoveOrganisation.container';

import './organisations-list.scss';

const formify = (organisations, onSelectRow, setOrg, search, filters) =>
  organisations
    .filter(
      (organisation) =>
        searchElement(organisation.name, search) ||
        searchElement(organisation.email_address, search) ||
        searchElement(organisation.created_by, search)
    )
    .filter((organisation) => filterElement(organisation, filters))
    .map(({ id, name, email_address, created_by, status, image }) => ({
      image: <ProfileImage imgSrc={image} initials={getOrganisationInitials({ name })} small />,
      name,
      email: email_address,
      createdBy: created_by,
      status: capitalise(status),
      onClick: () => onSelectRow(id),
      action: (
        <CloseCircleHollowIcon
          className="table__body-action--danger"
          onClick={(e) => {
            e.stopPropagation();
            setOrg({ id, name });
          }}
        />
      )
    }));

const OrganisationsList = ({ organisations }) => {
  const navigate = useNavigate();

  const { search, filters, filtering, searching, setFilterDefaults } = useFilters();
  const [orgToDelete, setOrgToDelete] = useState();

  const emptyMessage = useMemo(() => {
    return filtering || searching
      ? 'We can’t find the organisation that you are looking for.'
      : 'This table is empty. There are no organisations listed yet.';
  }, [filtering, searching]);

  useEffect(() => {
    setFilterDefaults({ status: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectRow = (id) => {
    navigate(`${id}`);
  };

  return (
    <section className="organisations-list">
      <Table
        cols={tableHeaders}
        columnModifiers={tableModifiers}
        rowData={formify(organisations, onSelectRow, setOrgToDelete, search, filters)}
        emptyTitle="No Organisations Found"
        emptySubtitle={emptyMessage}
      />
      <RemoveOrganisationContainer
        organisation={orgToDelete}
        show={orgToDelete}
        setShowModal={setOrgToDelete}
      />
    </section>
  );
};

export default OrganisationsList;
