export const tableHeaders = [
  { Header: 'Sport', accessor: 'name' },
  { Header: 'Created By', accessor: 'created_by' },
  { Header: 'Date Created', accessor: 'created_at' },
  { Header: '', accessor: 'action', disableSortBy: true }
];

export const tableModifiers = {
  action: {
    actions: true
  }
};
