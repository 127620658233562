import React, { useState } from 'react';

import { isEmptyFilter } from '@neslotech/eventhub-ui-kit';
import { isEmpty } from '@neslotech/utils';

import { FilterContext } from '../contexts/Filter.context';

const FilterProvider = ({ children }) => {
  const [filterDefaults, setFilterDefaults] = useState();
  const [filters, setFilters] = useState();
  const [search, setSearch] = useState('');
  const [filtering, setFiltering] = useState(false);
  const [searching, setSearching] = useState(false);

  const handleSearch = (searchValue) => {
    setSearch(searchValue);
    setFiltering(!isEmptyFilter(filters));
    setSearching(!isEmpty(searchValue));
  };

  const handleFilters = (filterValue) => {
    setFilters(filterValue);
    setFiltering(!isEmptyFilter(filterValue));
    setSearching(!isEmpty(search));
  };

  const handleFilterDefaults = (defaults) => {
    setFilters(defaults);
    setFilterDefaults(defaults);
  };

  const handleClearFilters = () => {
    setFilters(filterDefaults);
    setFiltering(false);
  };

  const value = {
    filters,
    search,
    filtering,
    searching,
    setFilterDefaults: handleFilterDefaults,
    setFilters: handleFilters,
    setSearch: handleSearch,
    onClearFilters: handleClearFilters
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export default FilterProvider;
