import React from 'react';

import { GaugeChart } from '@neslotech/eventhub-ui-kit';

import './stat-chart.scss';

const StatChart = ({ title, blurb, percentage }) => (
  <section className="stat-chart">
    <h5>{title}</h5>
    <small>{blurb}</small>
    <GaugeChart percentage={percentage} />
  </section>
);

export default StatChart;
