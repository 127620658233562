import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { confirmPayment, loadPayment } from '../../../../actions/entry/entry.actions';

import AdminLayout from '../../../../components/layout/admin/AdminLayout';
import PaymentDetails from '../../../../components/payments/details/PaymentDetails';

const PaymentContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const payment = useSelector(({ entry_store }) => entry_store.payment);
  const stateLoading = useSelector(({ entry_store }) => entry_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadPayment(id, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = (payload) => {
    dispatch(confirmPayment(id, payload));
  };

  return (
    <AdminLayout gutterless>
      <PageLayout fixed title="Awaiting Payment Verification" back>
        {loading && <Loader filled />}
        {!loading && <PaymentDetails payment={payment} onConfirm={handleConfirm} />}
      </PageLayout>
    </AdminLayout>
  );
};

export default PaymentContainer;
