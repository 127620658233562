import React from 'react';

import { getClassNames } from '@neslotech/utils';

import './badge.scss';

const Badge = ({ title, large, image }) => (
  <section className={getClassNames('badge', { large })}>
    <div className="badge__icon">{image}</div>
    {title && <label className="badge__title">{title}</label>}
  </section>
);

export default Badge;
