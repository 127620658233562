import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { loadSports } from '../../../actions/sport/sport.actions';

import FilterLayout from '../../../components/layout/filter/FilterLayout';
import AdminLayout from '../../../components/layout/admin/AdminLayout';
import SportsList from '../../../components/sports/SportsList';

import AddSportContainer from './add/AddSport.container';

const SportsContainer = () => {
  const dispatch = useDispatch();

  const sports = useSelector(({ sport_store }) => sport_store.sports);
  const stateLoading = useSelector(({ sport_store }) => sport_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    dispatch(loadSports(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAdd = () => {
    setShowAddModal(true);
  };

  return (
    <AdminLayout>
      <PageLayout title="Sport Management">
        <FilterLayout
          searchPlaceholder="Search for a sport..."
          actions={<Button label="Add New Sport" onClick={onAdd} />}
        >
          {loading && <Loader filled />}
          {!loading && <SportsList sports={sports} />}
          <AddSportContainer show={showAddModal} setShowModal={setShowAddModal} />
        </FilterLayout>
      </PageLayout>
    </AdminLayout>
  );
};

export default SportsContainer;
