export const tableHeaders = [
  { Header: '', accessor: 'profileImage', disableSortBy: true },
  { Header: 'First Name', accessor: 'firstName' },
  { Header: 'Last Name', accessor: 'lastName' },
  { Header: 'Email Address', accessor: 'email' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Role', accessor: 'role' },
  { Header: '', accessor: 'action', disableSortBy: true }
];

export const tableModifiers = {
  action: {
    actions: true
  }
};

export const getProfileInitials = ({ first_name, last_name }) => {
  if (!!first_name && !!last_name) {
    return `${first_name.charAt(0).toUpperCase()}${last_name.charAt(0).toUpperCase()}`;
  }
};
