import React from 'react';

import {
  Button,
  ButtonGroup,
  Checkbox,
  Form,
  FormAction,
  FormRow,
  TextArea
} from '@neslotech/eventhub-ui-kit';

import { useFormState } from '@neslotech/hooks';

import { INAPPROPRIATE_REASON, NOT_REACHABLE_REASON } from '../../../tools/constants';

import './reject-verification.scss';

const rules = {
  validates: {
    reason: ['isPresent'],
    message: ['isPresent']
  }
};

const serverify = ({ reason, message }) => ({
  reason,
  message
});

const RejectVerification = ({ onConfirmClick, onCancelClick }) => {
  const [form, setForm] = useFormState(undefined, rules);

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleOptionChange = (key) => {
    setForm({ ...form, reason: form?.reason !== key ? key : '' });
  };

  const handleConfirm = () => {
    const payload = serverify(form);
    onConfirmClick(payload);
  };

  return (
    <section className="reject-verification">
      <p className="reject-verification__message">
        Please select the reason(s) you are rejecting the verification of this organisation and
        leave an optional comment.
      </p>
      <Form>
        <FormRow>
          <Checkbox
            checked={form?.reason === NOT_REACHABLE_REASON}
            label="Organiser is not reachable to verify account details"
            onChange={() => handleOptionChange(NOT_REACHABLE_REASON)}
          />
        </FormRow>
        <FormRow>
          <Checkbox
            checked={form?.reason === INAPPROPRIATE_REASON}
            label="Organisation contains inappropriate or abusive content"
            onChange={() => handleOptionChange(INAPPROPRIATE_REASON)}
          />
        </FormRow>
        <FormRow>
          <TextArea
            name="message"
            placeholder="Message"
            label="Message:"
            onChange={handleChange}
            value={form?.message}
            error={form?.messages?.message}
          />
        </FormRow>
        <ButtonGroup>
          <FormAction label="Confirm" onClick={handleConfirm} disabled={!form?.valid} />
          <Button label="Cancel" secondary onClick={onCancelClick} />
        </ButtonGroup>
      </Form>
    </section>
  );
};

export default RejectVerification;
