import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { loadPayments } from '../../../actions/entry/entry.actions';

import AdminLayout from '../../../components/layout/admin/AdminLayout';
import FilterLayout from '../../../components/layout/filter/FilterLayout';
import PaymentsList from '../../../components/payments/PaymentsList';

const PaymentsContainer = () => {
  const dispatch = useDispatch();

  const payments = useSelector(({ entry_store }) => entry_store.payments);
  const stateLoading = useSelector(({ entry_store }) => entry_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadPayments(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminLayout gutterless>
      <PageLayout title="Payment Management" fixed>
        <FilterLayout searchPlaceholder="Search for a user...">
          {loading && <Loader filled />}
          {!loading && <PaymentsList payments={payments} />}
        </FilterLayout>
      </PageLayout>
    </AdminLayout>
  );
};

export default PaymentsContainer;
