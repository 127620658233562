import React from 'react';

import { Button, ButtonGroup } from '@neslotech/eventhub-ui-kit';

import AccountDetailsSection from './account/AccountDetails';
import OrganisersSection from './organisers/Organisers';
import ContactDetailsSection from './contact/ContactDetails';

import './organisation-details.scss';

const VerificationActions = ({ onAccept, onReject, onRequestChange }) => (
  <section className="verification-actions">
    <ButtonGroup fluid>
      <Button label="Accept" onClick={onAccept} />
      <Button label="Reject" danger onClick={onReject} />
      <Button label="Request Change" light onClick={onRequestChange} />
    </ButtonGroup>
  </section>
);

const OrganisationDetails = ({ organisation, onAccept, onReject, onRequestChange }) => (
  <section className="organisation-details">
    <ContactDetailsSection organisation={organisation} />
    <section className="organisation-details__split">
      <OrganisersSection organisation={organisation} />
      <AccountDetailsSection organisation={organisation} />
    </section>
    {!organisation.verified && (
      <VerificationActions
        onAccept={onAccept}
        onReject={onReject}
        onRequestChange={onRequestChange}
      />
    )}
  </section>
);

export default OrganisationDetails;
