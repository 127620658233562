import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { searchElement, Table } from '@neslotech/eventhub-ui-kit';

import { tableHeaders, tableModifiers } from '../../containers/admin/sports/sports.helpers';

import { useFilters } from '../../hooks/useFilters';

import { ReactComponent as CloseCircleHollowIcon } from '../../icons/close-circle-hollow-icon.svg';

import RemoveSportContainer from '../../containers/admin/sports/remove/RemoveSport.container';

import './sports-list.scss';

const formify = (sports, onSelectRow, setSport, search) =>
  sports
    .filter((sport) => searchElement(sport.name, search))
    .map(({ id, name, created_by, created_at }) => ({
      id,
      name,
      created_by,
      created_at,
      onClick: () => onSelectRow(id),
      action: (
        <CloseCircleHollowIcon
          className="table__body-action--danger"
          onClick={(e) => {
            e.stopPropagation();
            setSport({ id, name });
          }}
        />
      )
    }));

const SportsList = ({ sports }) => {
  const navigate = useNavigate();

  const { search, searching } = useFilters();
  const [sportToDelete, setSportToDelete] = useState();

  const emptyMessage = useMemo(() => {
    return searching
      ? 'We can’t find the sport you are looking for.'
      : 'This table is empty. There are no sports yet.';
  }, [searching]);

  const onSelectRow = (id) => {
    navigate(`${id}`);
  };

  return (
    <section className="sports-list">
      <Table
        cols={tableHeaders}
        columnModifiers={tableModifiers}
        rowData={formify(sports, onSelectRow, setSportToDelete, search)}
        emptyTitle="No sports here"
        emptySubtitle={emptyMessage}
      />
      <RemoveSportContainer
        sport={sportToDelete}
        show={sportToDelete}
        setShowModal={setSportToDelete}
      />
    </section>
  );
};

export default SportsList;
