import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, PageLayout, RadioGroup } from '@neslotech/eventhub-ui-kit';
import { noOp } from '@neslotech/utils';

import { loadEvents } from '../../../actions/event/event.actions';

import { ReactComponent as ChevronRightIcon } from '../../../icons/chevron-right-icon.svg';

import AdminLayout from '../../../components/layout/admin/AdminLayout';
import FilterLayout from '../../../components/layout/filter/FilterLayout';
import EventsList from '../../../components/events/EventsList';

const menuItems = (handleChange, filters) => [
  {
    text: 'Status',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        onChange={handleChange}
        value={filters?.status}
        name="status"
        items={[
          { label: 'Upcoming', value: 'upcoming' },
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
          { label: 'Completed', value: 'completed' }
        ]}
      />
    )
  }
];

const EventsContainer = () => {
  const dispatch = useDispatch();

  const events = useSelector(({ event_store }) => event_store.events);
  const stateLoading = useSelector(({ event_store }) => event_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadEvents(() => setLoading(false)));
  }, [dispatch]);

  return (
    <AdminLayout>
      <PageLayout title="Event Management">
        <FilterLayout menuItems={menuItems} searchPlaceholder="Search for an event...">
          {loading && <Loader filled />}
          {!loading && <EventsList events={events} />}
        </FilterLayout>
      </PageLayout>
    </AdminLayout>
  );
};

export default EventsContainer;
