import { combineReducers } from 'redux';

import { authReducer } from './auth/auth.reducer';
import { systemReducer } from './system/system.reducer';
import { userReducer } from './user/user.reducer';
import { organisationReducer } from './organisation/organisation.reducer';
import { eventReducer } from './event/event.reducer';
import { competitionReducer } from './competition/competition.reducer';
import { profileReducer } from './profile/profile.reducer';
import { verificationReducer } from './verification/verification.reducer';
import { entryReducer } from './entry/entry.reducer';
import { sportReducer } from './sport/sport.reducer';

const rootReducer = combineReducers({
  auth_store: authReducer,
  system_store: systemReducer,
  user_store: userReducer,
  organisation_store: organisationReducer,
  event_store: eventReducer,
  competition_store: competitionReducer,
  profile_store: profileReducer,
  verification_store: verificationReducer,
  entry_store: entryReducer,
  sport_store: sportReducer
});

export default rootReducer;
