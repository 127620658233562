import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { noOp } from '@neslotech/utils';
import { Loader, PageLayout, RadioGroup } from '@neslotech/eventhub-ui-kit';

import { loadOrganisations } from '../../../actions/organisation/organisation.actions';

import { ReactComponent as ChevronRightIcon } from '../../../icons/chevron-right-icon.svg';

import AdminLayout from '../../../components/layout/admin/AdminLayout';
import OrganisationsList from '../../../components/organisations/OrganisationsList';
import FilterLayout from '../../../components/layout/filter/FilterLayout';

const menuItems = (handleChange, filters) => [
  {
    text: 'Status',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        onChange={handleChange}
        value={filters?.status}
        name="status"
        items={[
          { label: 'Inactive', value: 'inactive' },
          { label: 'Active', value: 'active' }
        ]}
      />
    )
  }
];

const OrganisationsContainer = () => {
  const dispatch = useDispatch();

  const organisations = useSelector(({ organisation_store }) => organisation_store.organisations);
  const stateLoading = useSelector(({ organisation_store }) => organisation_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadOrganisations(() => setLoading(false)));
  }, [dispatch]);

  return (
    <AdminLayout>
      <PageLayout title="Organisation Management">
        <FilterLayout menuItems={menuItems} searchPlaceholder="Search for an organisation...">
          {loading && <Loader filled />}
          {!loading && <OrganisationsList organisations={organisations} />}
        </FilterLayout>
      </PageLayout>
    </AdminLayout>
  );
};

export default OrganisationsContainer;
