import React from 'react';

import {
  Button,
  Form,
  FormRow,
  Input,
  RadioGroup,
  Table,
  TextArea
} from '@neslotech/eventhub-ui-kit';

import { tableHeaders } from './event-details.helpers';

import { ReactComponent as CalendarIcon } from '../../../icons/calendar-icon.svg';
import { ReactComponent as ClockIcon } from '../../../icons/clock-icon.svg';
import { ReactComponent as LocationIcon } from '../../../icons/location-icon.svg';

import ContextDetails from '../../context/ContextDetails';
import DetailsSection from '../../section/DetailsSection';
import Select from '../../select/Select';

import './event-details.scss';

const EventDetailsSection = ({ event }) => (
  <section className="event-details-section">
    <Form>
      <FormRow fluid>
        <Input placeholder="Event Name" label="Event Name" value={event.name} disabled />
        <Input
          placeholder="Start and End Time"
          label="Start and End Time"
          action={<ClockIcon />}
          value={`${event.start_time} - ${event.end_time}`}
          disabled
        />
      </FormRow>
      <FormRow fluid>
        <Input
          placeholder="Start and End Date"
          label="Start and End Date"
          action={<CalendarIcon />}
          value={`${event.start_date} - ${event.end_date}`}
          disabled
        />
        <Input
          placeholder="Location"
          label="Location"
          action={<LocationIcon />}
          value={event.venue}
          disabled
        />
      </FormRow>
      <FormRow fluid>
        <Select
          label="Competition Type"
          placeholder="Competition Type"
          value={event.competition?.competition_type}
          disabled
        />
        <RadioGroup
          label="National or Regional Event"
          disabled
          fluid
          name="national"
          value={event.national}
          items={[
            { label: 'National', value: true },
            { label: 'Regional', value: false }
          ]}
        />
      </FormRow>
      <FormRow fluid>
        <Input
          placeholder="Entry Start and End Dates"
          label="Entry Start and End Dates"
          action={<CalendarIcon />}
          value={`${event.start_date} - ${event.end_date}`}
          disabled
        />
        <Select
          label="Amount of Attendees"
          placeholder="Amount of Attendees"
          value={event.attendee_count}
          disabled
        />
      </FormRow>
      <FormRow fluid>
        <RadioGroup
          label="Refundable event"
          disabled
          fluid
          name="refundable"
          value={event.refundable}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
        <Input placeholder="Attendee Price" label="Attendee Price" value={event.price} disabled />
      </FormRow>
      <FormRow>
        <RadioGroup
          label="Do you want participants entries to be visible to other entrants?"
          disabled
          fluid
          name="visible_entries"
          value={event.visible_entries}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
      </FormRow>
    </Form>
  </section>
);

const DescriptionSection = ({ event }) => (
  <DetailsSection title="Event Description">
    <section className="description-section">
      <Form>
        <FormRow>
          <TextArea placeholder="Enter Event Description" value={event.description} disabled />
        </FormRow>
      </Form>
    </section>
  </DetailsSection>
);

const mapRows = (allocations) =>
  (allocations ?? []).map(({ name, start_time, price }) => ({
    name,
    start_time,
    price: Number(price).toFixed(2)
  }));

const ClassesSection = ({ event }) => (
  <DetailsSection title="Classes in the Event">
    <section className="classes-section">
      <Table cols={tableHeaders} rowData={mapRows(event.class_allocations)} />
    </section>
  </DetailsSection>
);

const EventDetails = ({ event, onDelete }) => (
  <section className="event-details">
    <ContextDetails
      context={event}
      actions={[<Button key="remove" label="Remove Event" danger onClick={onDelete} />]}
    />
    <section className="event-details__details">
      <DetailsSection title="Event Details">
        <EventDetailsSection event={event} />
      </DetailsSection>
      <DescriptionSection event={event} />
      <ClassesSection event={event} />
    </section>
  </section>
);

export default EventDetails;
