import React, { createRef, useState } from 'react';

import {
  Button,
  ButtonGroup,
  Card,
  Form,
  FormAction,
  FormRow,
  Input,
  ProfileImage,
  toBase64
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import { getClassNames } from '@neslotech/utils';

import { getProfileInitials } from '../../containers/admin/users/users.helpers';

import { ReactComponent as CameraIcon } from '../../icons/camera-icon.svg';
import { ReactComponent as PencilIcon } from '../../icons/pencil-icon.svg';

import FileUpload from '../upload/FileUpload';

import './profile-details.scss';

const rules = {
  validates: {
    emailAddress: ['isPresent', 'isEmail'],
    phoneNumber: ['isNumeric']
  }
};

const formify = (profile) => ({
  firstName: profile.first_name,
  lastName: profile.last_name,
  phoneNumber: profile.phone_number,
  emailAddress: profile.email,
  image: profile.image
});

const serverify = (form) => ({
  first_name: form.firstName,
  last_name: form.lastName,
  phone_number: form.phoneNumber,
  email: form.emailAddress,
  image: form.image
});

const ProfileDetails = ({ profile, onSave }) => {
  const [form, setForm] = useFormState(formify(profile), rules);
  const [editMode, setEditMode] = useState(false);

  const fileRef = createRef();

  const handleChange = (newState) => setForm({ ...form, ...newState, touched: true });

  const handleSubmit = () => {
    const payload = serverify(form);
    setForm({ ...form, touched: false });
    onSave(payload);
    setEditMode(false);
  };

  const handleCancel = () => {
    setEditMode(false);
    setForm(formify(profile));
  };

  const handleFileChange = async (file) => {
    const base64 = await toBase64(file);
    setForm({ ...form, image: base64 });
  };

  return (
    <Card>
      <section className="profile-details">
        <section className="profile-details__image">
          <FileUpload fileRef={fileRef} onChange={handleFileChange}>
            <ProfileImage
              imgSrc={form?.image ?? profile.image}
              initials={getProfileInitials(profile)}
            />
            {editMode && (
              <CameraIcon
                className="profile-details__image-action"
                onClick={() => fileRef.current.click()}
              />
            )}
          </FileUpload>
        </section>
        <section className="profile-details__form">
          <h4 className="profile-details__form-title">Personal Details</h4>
          <Form>
            <FormRow fluidSpaced>
              <Input
                name="firstName"
                placeholder="First Name"
                label="First Name"
                value={form?.firstName}
                onChange={handleChange}
                disabled={!editMode}
              />
              <Input
                name="lastName"
                placeholder="Last Name"
                label="Last Name"
                value={form?.lastName}
                onChange={handleChange}
                disabled={!editMode}
              />
            </FormRow>
            <FormRow fluidSpaced>
              <Input
                name="phoneNumber"
                placeholder="Phone Number"
                label="Phone Number"
                value={form?.phoneNumber}
                error={form?.messages?.phoneNumber}
                onChange={handleChange}
                disabled={!editMode}
              />
              <Input
                name="emailAddress"
                placeholder="Email Address"
                label="Email Address"
                value={form?.emailAddress}
                error={form?.messages?.emailAddress}
                onChange={handleChange}
                disabled={!editMode}
              />
            </FormRow>
            {editMode && (
              <ButtonGroup fluid>
                <FormAction
                  label="Save Changes"
                  disabled={!form?.valid || !form?.touched}
                  onClick={handleSubmit}
                />
                <Button label="Cancel" tertiary onClick={handleCancel} />
              </ButtonGroup>
            )}
          </Form>
        </section>
        <section className={getClassNames('profile-details__action', { disabled: editMode })}>
          <PencilIcon onClick={() => setEditMode(true)} />
        </section>
      </section>
    </Card>
  );
};

export default ProfileDetails;
