import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import App from './App';
import reportWebVitals from './reportWebVitals';

import authSaga from './sagas/auth/auth.saga';
import userSaga from './sagas/user/user.saga';
import organisationSaga from './sagas/organisation/organisation.saga';
import eventSaga from './sagas/event/event.saga';
import competitionSaga from './sagas/competition/competition.saga';
import profileSaga from './sagas/profile/profile.saga';
import verificationSaga from './sagas/verification/verification.saga';
import entrySaga from './sagas/entry/entry.saga';
import sportSaga from './sagas/sport/sport.saga';

import rootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(authSaga);
sagaMiddleware.run(userSaga);
sagaMiddleware.run(organisationSaga);
sagaMiddleware.run(eventSaga);
sagaMiddleware.run(competitionSaga);
sagaMiddleware.run(profileSaga);
sagaMiddleware.run(verificationSaga);
sagaMiddleware.run(entrySaga);
sagaMiddleware.run(sportSaga);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
