import React from 'react';
import { useDispatch } from 'react-redux';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { inviteAdmins } from '../../../../actions/user/user.actions';

import InviteNewAdmin from '../../../../components/users/InviteNewAdmin';

const InviteNewAdminContainer = ({ show, setShowModal }) => {
  const dispatch = useDispatch();

  const onClose = () => setShowModal(false);

  const onInvite = ({ emails }) => {
    dispatch(inviteAdmins(emails, onClose));
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Invite New Admins">
            <InviteNewAdmin onInvite={onInvite} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default InviteNewAdminContainer;
