import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { removeCompetition } from '../../../../actions/competition/competition.actions';

import RemoveCompetition from '../../../../components/competitions/remove/RemoveCompetition';

const RemoveCompetitionContainer = ({ competition, show, setShowModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClose = () => setShowModal(false);

  const handleConfirm = () => {
    dispatch(removeCompetition(competition.id, navigate));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Competition">
            <RemoveCompetition
              competition={competition}
              onConfirmClick={handleConfirm}
              onCancelClick={onClose}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveCompetitionContainer;
