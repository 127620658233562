export const tableHeaders = [
  { Header: '', accessor: 'image', disableSortBy: true },
  { Header: 'Competition', accessor: 'name' },
  { Header: 'Sport', accessor: 'sport' },
  { Header: 'Comp Type', accessor: 'competitionType' },
  { Header: 'National / Regional', accessor: 'national' },
  { Header: 'Regulated', accessor: 'regulated' },
  { Header: 'Start Date', accessor: 'startDate' },
  { Header: 'Status', accessor: 'status' },
  { Header: '', accessor: 'action', disableSortBy: true }
];

export const tableModifiers = {
  action: {
    actions: true
  }
};

export const getCompetitionInitials = ({ name }) => {
  return name
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('');
};
