import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { loadDashboardData, loadProfile } from '../../../actions/profile/profile.actions';

import AdminLayout from '../../../components/layout/admin/AdminLayout';
import Dashboard from '../../../components/dashboard/Dashboard';

const formify = ({
  total_users,
  organizer_count,
  participant_count,
  uninvolved_count,
  upcoming_competition,
  upcoming_event
}) => ({
  totalUsers: total_users,
  organizersCount: organizer_count,
  organizersPercentage: Number(((organizer_count / total_users) * 100).toFixed(2)),
  participantsCount: participant_count,
  participantsPercentage: Number(((participant_count / total_users) * 100).toFixed(2)),
  uninvolvedCount: uninvolved_count,
  uninvolvedPercentage: Number(((uninvolved_count / total_users) * 100).toFixed(2)),
  upcomingCompetition: upcoming_competition,
  upcomingEvent: upcoming_event
});

const DashboardContainer = () => {
  const dispatch = useDispatch();

  const stateLoading = useSelector(({ profile_store }) => profile_store.loading);
  const profile = useSelector(({ profile_store }) => profile_store.profile);
  const dashboardData = useSelector(({ profile_store }) => profile_store.dashboard);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadProfile());
    dispatch(loadDashboardData(() => setLoading(false)));
  }, [dispatch]);

  return (
    <AdminLayout>
      {loading && <Loader filled />}
      {!loading && (
        <PageLayout bold title={`Welcome, ${profile?.full_name}`}>
          <Dashboard dashboard={formify(dashboardData)} />
        </PageLayout>
      )}
    </AdminLayout>
  );
};

export default DashboardContainer;
