import React from 'react';

import { ReactComponent as Logo } from '../../../images/logo.svg';

import './auth-layout.scss';

const renderLogo = (noLogo, title) =>
  !noLogo && (
    <header className="auth-layout__content-header">
      <Logo />
      <p>{title}</p>
    </header>
  );

const renderNoLogo = (noLogo, title, subtitle) =>
  noLogo && (
    <header className="auth-layout__content-header">
      <h3>{title}</h3>
      {subtitle && <p className="auth-layout__subtitle">{subtitle}</p>}
    </header>
  );

const AuthLayout = ({ title, subtitle, noLogo, children }) => (
  <section className="auth-layout">
    <section className="auth-layout__content">
      {renderLogo(noLogo, title)}
      {renderNoLogo(noLogo, title, subtitle)}
      <div className="auth-layout__content-form">{children}</div>
    </section>
  </section>
);

AuthLayout.defaultProps = {
  noLogo: false
};

export default AuthLayout;
