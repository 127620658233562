import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { addSport } from '../../../../actions/sport/sport.actions';

import AddSport from '../../../../components/sports/add/AddSport';

const AddSportContainer = ({ show, setShowModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClose = () => setShowModal(false);

  const handleConfirm = (payload) => {
    dispatch(addSport(payload, navigate));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Create New Sport">
            <AddSport onConfirmClick={handleConfirm} onCancelClick={onClose} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default AddSportContainer;
