import React from 'react';

import { Card, ContextCard, ContextInfoList } from '@neslotech/eventhub-ui-kit';

import './context-details.scss';

const ContextDetails = ({ context, actions }) => (
  <section className="context-details">
    <ContextCard context={context} image={<img src={context.image} alt={context.name} />}>
      <ContextInfoList term="Created By" value={context.created_by} />
      <ContextInfoList
        term="Date created"
        value={new Date(context.created_at).toLocaleDateString()}
      />
    </ContextCard>
    <Card>
      <section className="context-details__actions">{actions.map((action) => action)}</section>
    </Card>
  </section>
);

export default ContextDetails;
