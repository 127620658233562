import React from 'react';

import { PageLayout } from '@neslotech/eventhub-ui-kit';

import AdminLayout from '../../../components/layout/admin/AdminLayout';

const StatsContainer = () => (
  <AdminLayout>
    <PageLayout title="Statistics">
      <h1>Stats will go here</h1>
    </PageLayout>
  </AdminLayout>
);

export default StatsContainer;
