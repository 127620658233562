import React, { useCallback, useMemo, useState } from 'react';

import {
  AddAction,
  Button,
  ButtonGroup,
  Checkbox,
  DetailsSection,
  Form,
  FormRow,
  Input,
  Table
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';
import { generateId, getClassNames, isEmpty, isUndefined } from '@neslotech/utils';

import { ReactComponent as PencilIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../icons/x-icon.svg';

import './sport-details.scss';

const classTableHeaders = [
  { Header: '', accessor: 'icon', disableSortBy: true },
  { Header: 'Class Name', accessor: 'name' },
  { Header: 'Allow Discount', accessor: 'discountable' }
];

const rules = {
  validates: {
    name: ['isPresent']
  }
};

const getEmptyRow = () => ({
  _id: generateId(),
  name: '',
  discount: false
});

const formify = (sport) => ({
  name: sport.name,
  classes: (sport.classes ?? [getEmptyRow()]).map((klass) => ({ ...klass, _id: klass.id }))
});

const serverifyClasses = (form) =>
  (form.classes ?? [])
    .filter((klass) => !isEmpty(klass.name))
    .map((klass) => ({
      id: klass.id,
      name: klass.name,
      discountable: klass.discountable,
      _destroy: klass._destroy
    }));

const serverify = (form) => ({
  name: form.name,
  active: true,
  class_types_attributes: serverifyClasses(form)
});

const SportDetails = ({ sport, onSave, onDelete }) => {
  const [form, setForm] = useFormState(formify(sport), rules);
  const handleChange = useCallback(
    (newState) => setForm({ ...form, ...newState }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    setLoading(true);
    onSave(serverify(form), () => {
      setLoading(false);
      setEditMode(false);
    });
  };

  const addNewRow = () => {
    handleChange({ classes: [...form?.classes, getEmptyRow()] });
  };

  const removeRow = useCallback(
    (klass) => {
      const classes = (form?.classes ?? []).reduce((accum, currentClass) => {
        if (currentClass._id === klass._id) {
          currentClass._destroy = '1';
        }

        return [...accum, currentClass];
      }, []);
      handleChange({ classes });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form?.classes]
  );

  const rowData = useMemo(() => {
    const handleValueChange = (index, value) => {
      const classes = form.classes ?? [];
      let klass = classes[index];
      klass = {
        ...klass,
        ...value
      };
      classes[index] = klass;

      handleChange({ classes });
    };

    return (form?.classes ?? [])
      .filter((item) => !item._destroy)
      .map((klass, index) => ({
        name: (
          <Input
            borderless
            name="name"
            placeholder="Enter class name"
            value={klass.name}
            onChange={(value) => handleValueChange(index, value)}
          />
        ),
        discountable: (
          <Checkbox
            name="discountable"
            label="Allow upfront competition discounts"
            checked={klass.discountable}
            onChange={(value) => handleValueChange(index, value)}
          />
        ),
        icon: <RemoveIcon onClick={() => removeRow(klass)} />
      }));
  }, [form?.classes, handleChange, removeRow]);

  return (
    <article className={getClassNames('sport-details', { editMode })}>
      <DetailsSection title="" icon={<PencilIcon onClick={() => setEditMode(true)} />} padded>
        <Form>
          <FormRow
            singleRow={isUndefined(sport?.num_of_competitions)}
            fluidSpaced={!isUndefined(sport?.num_of_competitions)}
          >
            <Input
              name="name"
              label="Sport Name"
              placeholder="Enter sport name"
              value={form?.name}
              onChange={handleChange}
              error={form?.messages?.name}
              disabled={!editMode}
            />
            {!isUndefined(sport?.num_of_competitions) && (
              <Input
                name="numOfCompetitions"
                label="Competitions used in"
                placeholder="Competitions used in"
                value={sport?.num_of_competitions}
                disabled
              />
            )}
          </FormRow>
        </Form>
      </DetailsSection>
      <DetailsSection
        title="Create your classes"
        subtitle="Create the classes that the organiser will be able to select to use in their competitions"
        icon={<PencilIcon onClick={() => setEditMode(true)} />}
      >
        <Table cols={classTableHeaders} rowData={rowData} pageLength={30} />
        {editMode && <AddAction title="Add new class" onClick={addNewRow} />}
      </DetailsSection>
      <ButtonGroup fluid spaced>
        {editMode && (
          <Button
            loading={loading}
            label="Save Changes"
            disabled={!form?.valid}
            onClick={handleSave}
          />
        )}
        <Button label="Delete Sport" danger onClick={onDelete} />
      </ButtonGroup>
    </article>
  );
};

export default SportDetails;
