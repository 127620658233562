import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ForgotPasswordContainer from './forgot-password/ForgotPassword.container';
import ResetPasswordContainer from './reset-password/ResetPassword.container';
import VerifyEmailContainer from './verify-email/VerifyEmail.container';
import CompleteProfileContainer from './complete-profile/CompleteProfile.container';
import LoginContainer from './login/Login.container';
import PasswordExpiredLinkContainer from './expired-link/PasswordExpiredLink.container';
import VerificationExpiredLinkContainer from './expired-link/VerificationExpiredLink.container';

const AuthContainer = () => (
  <Routes>
    <Route exact path="/" element={<LoginContainer />} />
    <Route exact path="/password/forgot" element={<ForgotPasswordContainer />} />
    <Route exact path="/password/:token/reset" element={<ResetPasswordContainer />} />
    <Route exact path="/password/:token/expired" element={<PasswordExpiredLinkContainer />} />
    <Route exact path="/profile/:token/verify-email" element={<VerifyEmailContainer />} />
    <Route exact path="/profile/:token/expired" element={<VerificationExpiredLinkContainer />} />
    <Route exact path="/profile/:token/complete-profile" element={<CompleteProfileContainer />} />
  </Routes>
);

export default AuthContainer;
