import React from 'react';
import { arrayOf, func, oneOfType, string } from 'prop-types';

import { noOp } from '@neslotech/utils';

const FileUpload = ({ fileTypes, fileRef, children, onChange }) => {
  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];
    onChange(selectedFile);
  };

  return (
    <section className="file-upload">
      {children}
      <input accept={fileTypes} type="file" hidden ref={fileRef} onChange={onFileChange} />
    </section>
  );
};

FileUpload.defaultProps = {
  onChange: noOp,
  fileRef: undefined,
  fileTypes: 'image/*'
};

FileUpload.propTypes = {
  onChange: func,
  onRemove: func,
  fileTypes: oneOfType([arrayOf(string), string])
};

export default FileUpload;
