import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { loadProfile, updateProfile } from '../../../actions/profile/profile.actions';

import AdminLayout from '../../../components/layout/admin/AdminLayout';
import ProfileDetails from '../../../components/profile/ProfileDetails';

const ProfileContainer = () => {
  const dispatch = useDispatch();

  const profile = useSelector(({ profile_store }) => profile_store.profile);
  const stateLoading = useSelector(({ profile_store }) => profile_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadProfile(() => setLoading(false)));
  }, [dispatch]);

  const onSave = (payload) => {
    dispatch(updateProfile(payload));
  };

  return (
    <AdminLayout>
      <PageLayout title="My Profile">
        {loading && <Loader filled />}
        {!loading && <ProfileDetails onSave={onSave} profile={profile} />}
      </PageLayout>
    </AdminLayout>
  );
};

export default ProfileContainer;
