import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterElement, ProfileImage, searchElement, Table } from '@neslotech/eventhub-ui-kit';
import { capitalise } from '@neslotech/utils';

import {
  getEventInitials,
  tableHeaders,
  tableModifiers
} from '../../containers/admin/events/events.helpers';

import { useFilters } from '../../hooks/useFilters';

import { ReactComponent as CloseCircleHollowIcon } from '../../icons/close-circle-hollow-icon.svg';

import RemoveEventContainer from '../../containers/admin/events/remove/RemoveEvent.container';

import './events-list.scss';

const formify = (events, onSelectRow, setEvent, search, filters) =>
  events
    .filter(
      (event) =>
        searchElement(event.name, search) ||
        searchElement(event.competition?.name, search) ||
        searchElement(event.venue, search)
    )
    .filter((event) => filterElement(event, filters))
    .map(({ id, name, competition, start_date, end_date, venue, status, image }) => ({
      image: <ProfileImage imgSrc={image} initials={getEventInitials({ name })} small />,
      name,
      competitionName: competition?.name,
      startDate: start_date,
      endDate: end_date,
      venue,
      status: capitalise(status),
      onClick: () => onSelectRow(id),
      action: (
        <CloseCircleHollowIcon
          className="table__body-action--danger"
          onClick={(e) => {
            e.stopPropagation();
            setEvent({ id, name });
          }}
        />
      )
    }));

const EventsList = ({ events }) => {
  const navigate = useNavigate();

  const { search, filters, filtering, searching, setFilterDefaults } = useFilters();
  const [eventToDelete, setEventToDelete] = useState();

  const emptyMessage = useMemo(() => {
    return filtering || searching
      ? 'There are no events listed under that name.'
      : 'This table is empty. There are no events listed yet.';
  }, [filtering, searching]);

  useEffect(() => {
    setFilterDefaults({ status: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectRow = (id) => {
    navigate(`${id}`);
  };

  return (
    <section className="events-list">
      <Table
        cols={tableHeaders}
        columnModifiers={tableModifiers}
        rowData={formify(events, onSelectRow, setEventToDelete, search, filters)}
        emptyTitle="No Events Found"
        emptySubtitle={emptyMessage}
      />
      <RemoveEventContainer
        event={eventToDelete}
        show={eventToDelete}
        setShowModal={setEventToDelete}
      />
    </section>
  );
};

export default EventsList;
