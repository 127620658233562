import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getRememberMeCookie } from '../../../tools/auth.util';

import { authenticate, REMEMBER_ME } from '../../../actions/auth/auth.actions';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import Login from '../../../components/auth/login/Login';

const LoginContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogin = (credentials, rememberMe, onComplete) => {
    dispatch(authenticate(credentials, rememberMe, navigate, onComplete));
  };

  useEffect(() => {
    const token = getRememberMeCookie();
    if (token) {
      dispatch({ type: REMEMBER_ME, token });
      navigate('/dashboard');
    }
  }, [dispatch, navigate]);

  return (
    <AuthLayout title="Login to continue">
      <Login onLogin={onLogin} />
    </AuthLayout>
  );
};

export default LoginContainer;
