import React from 'react';

import { Dropdown, Input } from '@neslotech/eventhub-ui-kit';
import { getClassNames } from '@neslotech/utils';

import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down-icon.svg';

import './select.scss';

const Select = ({ placeholder, label, disabled, value, name, items }) => (
  <section className={getClassNames('select', { disabled })}>
    <Dropdown menuItems={items}>
      <Input
        label={label}
        name={name}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        action={<ChevronDownIcon />}
      />
    </Dropdown>
  </section>
);

Select.defaultProps = {
  items: []
};

export default Select;
