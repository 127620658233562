import React from 'react';
import { useDispatch } from 'react-redux';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { removeEvent } from '../../../../actions/event/event.actions';

import RemoveEvent from '../../../../components/events/remove/RemoveEvent';

const RemoveEventContainer = ({ event, show, setShowModal }) => {
  const dispatch = useDispatch();

  const onClose = () => setShowModal(false);

  const handleConfirm = () => {
    dispatch(removeEvent(event.id));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Event">
            <RemoveEvent event={event} onConfirmClick={handleConfirm} onCancelClick={onClose} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveEventContainer;
