import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@neslotech/eventhub-ui-kit';
import { getClassNames } from '@neslotech/utils';

import './upcoming-card.scss';

const UpcomingCard = ({ title, path, name, description, type, date, onClick, empty }) => (
  <section className="upcoming-card">
    <header>
      <h5>{title}</h5>
      <Link to={path}>
        <label>View All</label>
      </Link>
    </header>
    <section className={getClassNames('upcoming-card__details', { empty })}>
      {empty && <p>There are no upcoming {type.toLowerCase()}s!</p>}
      <label>{name}</label>
      <small>{description}</small>
      {!empty && (
        <footer>
          <Button label={`View ${type}`} tertiary onClick={onClick} />
          <label>{date}</label>
        </footer>
      )}
    </section>
  </section>
);

export default UpcomingCard;
