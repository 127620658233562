import { SET_SPORT, SET_SPORTS } from '../../actions/sport/sport.actions';

export const initialState = {
  sports: [],
  sport: undefined
};

export const sportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPORTS:
      const { sports } = action;
      return {
        sports
      };
    case SET_SPORT:
      const { sport } = action;
      return {
        sport
      };
    default:
      return state;
  }
};
