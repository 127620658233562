import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProfileImage, searchElement, Table } from '@neslotech/eventhub-ui-kit';

import {
  getProfileInitials,
  tableHeaders,
  tableModifiers
} from '../../containers/admin/verifications/verifications.helpers';

import { useFilters } from '../../hooks/useFilters';

import './verifications-list.scss';

const formify = (verifications, onSelectRow, search) =>
  verifications
    .filter((verification) => searchElement(verification.name, search))
    .map(({ id, name, email_address, phone_number, physical_address, created_by, image }) => ({
      image: <ProfileImage imgSrc={image} initials={getProfileInitials({ name })} small />,
      name,
      emailAddress: email_address,
      contactNumber: phone_number,
      address: physical_address,
      createdBy: created_by,
      onClick: () => onSelectRow(id)
    }));

const VerificationsList = ({ verifications }) => {
  const navigate = useNavigate();

  const { search, filtering, searching } = useFilters();

  const emptyMessage = useMemo(() => {
    return filtering || searching
      ? 'We can’t find the organisation you are looking for.'
      : 'This table is empty. There are no verifications yet.';
  }, [filtering, searching]);

  const onSelectRow = (id) => {
    navigate(`${id}`);
  };

  return (
    <section className="verifications-list">
      <Table
        cols={tableHeaders}
        columnModifiers={tableModifiers}
        rowData={formify(verifications, onSelectRow, search)}
        emptyTitle="No One Is Here"
        emptySubtitle={emptyMessage}
      />
    </section>
  );
};

export default VerificationsList;
