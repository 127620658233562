import React from 'react';
import { Link } from 'react-router-dom';

import { Form, FormAction, FormRow, Input } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './verify-email.scss';

const rules = {
  validates: {
    email: ['isPresent', 'isEmail']
  }
};

const serverify = ({ email }) => ({
  email
});

const VerifyEmail = ({ onVerifyEmail }) => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleSubmit = () => {
    const payload = serverify(form);
    onVerifyEmail(payload);
  };

  return (
    <section className="verify-email">
      <Form>
        <FormRow>
          <Input
            name="email"
            label="Email Address"
            placeholder="Email Address"
            type="email"
            onChange={handleChange}
            value={form?.email}
            error={form?.messages?.email}
          />
        </FormRow>
        <Link to="/complete-profile">
          <FormAction label="Continue" onClick={handleSubmit} disabled={!form?.valid} />
        </Link>
      </Form>
    </section>
  );
};

export default VerifyEmail;
