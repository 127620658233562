import React from 'react';

import { Button, ButtonGroup, Form, FormRow, Input } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './add-sport.scss';

const rules = {
  validates: {
    name: ['isPresent']
  }
};

const serverify = (form) => ({
  name: form.name,
  active: true
});

const AddSport = ({ onConfirmClick, onCancelClick }) => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleConfirmClick = () => {
    const payload = serverify(form);
    onConfirmClick(payload);
  };

  return (
    <section className="add-sport">
      <div className="add-sport__message">
        <p>Enter the name of the new sport you would like to add to EventHub</p>
      </div>
      <Form>
        <FormRow>
          <Input
            name="name"
            label="Sport Name"
            placeholder="Enter sport name"
            onChange={handleChange}
            value={form?.name}
            error={form?.messages?.name}
          />
        </FormRow>
      </Form>
      <ButtonGroup>
        <Button label="Confirm" onClick={handleConfirmClick} />
        <Button label="Cancel" onClick={onCancelClick} secondary />
      </ButtonGroup>
    </section>
  );
};

export default AddSport;
