import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Card } from '@neslotech/eventhub-ui-kit';

import { ReactComponent as VerificationRequestsIcon } from '../../icons/verification-requests-icon.svg';
import { ReactComponent as PaymentsIcon } from '../../icons/payments-icon.svg';

import StatChart from './chart/StatChart';
import UpcomingCard from './card/UpcomingCard';

import './dashboard.scss';

const Dashboard = ({ dashboard }) => {
  const navigate = useNavigate();

  return (
    <article className="dashboard">
      <section className="dashboard__detail">
        <section className="dashboard__actions">
          <Card noShadow>
            <section className="dashboard__verification-requests">
              <section className="dashboard__verification-requests-detail">
                <h5>Verification Requests</h5>
                <Link to="/verifications">
                  <label>View All</label>
                </Link>
              </section>
              <VerificationRequestsIcon />
            </section>
          </Card>
          <Card noShadow>
            <section className="dashboard__payments">
              <section className="dashboard__payments-detail">
                <h5>Manage Payments</h5>
                <Link to="/payments">
                  <label>View All</label>
                </Link>
              </section>
              <PaymentsIcon />
            </section>
          </Card>
        </section>
        <Card noShadow>
          <section className="dashboard__users">
            <header>
              <h5>Total Users: {dashboard.totalUsers}</h5>
              <Link to="/users">
                <label>View All</label>
              </Link>
            </header>
            <section className="dashboard__stats">
              <StatChart
                title="Organizers"
                blurb={`${dashboard.organizersCount} of the ${dashboard.totalUsers} users are organizers in an organization`}
                percentage={dashboard.organizersPercentage}
              />
              <StatChart
                title="Participants"
                blurb={`${dashboard.participantsCount} of the ${dashboard.totalUsers} users are participants`}
                percentage={dashboard.participantsPercentage}
              />
              <StatChart
                title="User Accounts"
                blurb={`${dashboard.uninvolvedCount} of the ${dashboard.totalUsers} users are uninvolved user accounts`}
                percentage={dashboard.uninvolvedPercentage}
              />
            </section>
          </section>
        </Card>
      </section>
      <section className="dashboard__upcoming">
        <Card noShadow>
          <UpcomingCard
            empty={!dashboard.upcomingCompetition}
            title="Upcoming Competition"
            type="Competition"
            path="/competitions"
            onClick={() => navigate(`/competitions/${dashboard.upcomingCompetition?.id}`)}
            name={dashboard.upcomingCompetition?.name}
            description={dashboard.upcomingCompetition?.description}
            date={dashboard.upcomingCompetition?.start_date}
          />
          <UpcomingCard
            empty={!dashboard.upcomingEvent}
            title="Upcoming Event"
            type="Event"
            path="/events"
            onClick={() => navigate(`/events/${dashboard.upcomingEvent?.id}`)}
            name={dashboard.upcomingEvent?.name}
            description={dashboard.upcomingEvent?.description}
            date={dashboard.upcomingEvent?.start_date}
          />
        </Card>
      </section>
    </article>
  );
};

export default Dashboard;
