import React from 'react';

import { Card } from '@neslotech/eventhub-ui-kit';

import Badge from '../../../badge/Badge';

import './organisation-badges.scss';

const OrganisationBadges = ({ organisations }) => (
  <Card>
    <section className="organisation-badges">
      <header>
        <h4>Organisation Badges</h4>
      </header>
      <section className="organisation-badges__badges">
        {organisations.map((organisation) => (
          <Badge
            key={organisation.id}
            large
            title={organisation.name}
            image={<img src={organisation.image} alt={organisation.name} />}
          />
        ))}
      </section>
    </section>
  </Card>
);

export default OrganisationBadges;
