import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { noOp } from '@neslotech/utils';
import { Loader, PageLayout, RadioGroup } from '@neslotech/eventhub-ui-kit';

import { loadCompetitions } from '../../../actions/competition/competition.actions';

import { ReactComponent as ChevronRightIcon } from '../../../icons/chevron-right-icon.svg';

import CompetitionsList from '../../../components/competitions/CompetitionsList';
import AdminLayout from '../../../components/layout/admin/AdminLayout';
import FilterLayout from '../../../components/layout/filter/FilterLayout';

const menuItems = (handleChange, filters) => [
  {
    text: 'Status',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        onChange={handleChange}
        value={filters?.status}
        name="status"
        items={[
          { label: 'Upcoming', value: 'upcoming' },
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
          { label: 'Completed', value: 'completed' }
        ]}
      />
    )
  },
  {
    text: 'Regulated',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        onChange={handleChange}
        value={filters?.regulated}
        name="regulated"
        items={[
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]}
      />
    )
  },
  {
    text: 'National/Regional',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        onChange={handleChange}
        value={filters?.national}
        name="national"
        items={[
          { label: 'National', value: true },
          { label: 'Regional', value: false }
        ]}
      />
    )
  }
];

const CompetitionsContainer = () => {
  const dispatch = useDispatch();

  const competitions = useSelector(({ competition_store }) => competition_store.competitions);
  const stateLoading = useSelector(({ competition_store }) => competition_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadCompetitions(() => setLoading(false)));
  }, [dispatch]);

  return (
    <AdminLayout>
      <PageLayout title="Competition Management">
        <FilterLayout menuItems={menuItems} searchPlaceholder="Search for a competition...">
          {loading && <Loader filled />}
          {!loading && <CompetitionsList competitions={competitions} />}
        </FilterLayout>
      </PageLayout>
    </AdminLayout>
  );
};

export default CompetitionsContainer;
