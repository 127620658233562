import React from 'react';
import { useDispatch } from 'react-redux';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { removeUser } from '../../../../actions/user/user.actions';

import RemoveUser from '../../../../components/users/remove/RemoveUser';

const RemoveUserContainer = ({ user, show, setShowModal }) => {
  const dispatch = useDispatch();

  const onClose = () => setShowModal(false);

  const handleConfirm = () => {
    dispatch(removeUser(user.id));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove User">
            <RemoveUser user={user} onConfirmClick={handleConfirm} onCancelClick={onClose} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveUserContainer;
