import React from 'react';
import { useDispatch } from 'react-redux';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { removeOrganisation } from '../../../../actions/organisation/organisation.actions';

import RemoveOrganisation from '../../../../components/organisations/remove/RemoveOrganisation';

const RemoveOrganisationContainer = ({ organisation, show, setShowModal }) => {
  const dispatch = useDispatch();

  const onClose = () => setShowModal(false);

  const handleConfirm = () => {
    dispatch(removeOrganisation(organisation.id));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Organisation">
            <RemoveOrganisation
              organisation={organisation}
              onConfirmClick={handleConfirm}
              onCancelClick={onClose}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveOrganisationContainer;
