import React, { createRef } from 'react';

import { Button, Input } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';
import { getClassNames, isEmpty, isValuePresent } from '@neslotech/utils';

import { ReactComponent as CloseCircleIcon } from '../../icons/close-circle-icon.svg';

import './invite-admin.scss';

const rules = {
  validates: {
    email: ['isPresentAndEmailsIsEmpty', 'isEmail', 'emailDoesNotExist'],
    emails: ['isPresent']
  },

  emailDoesNotExist(value, form) {
    const exists = form.emails?.find(
      (existingEmail) => existingEmail.toLowerCase() === value.toLowerCase()
    );
    if (exists) {
      return 'Email already exists in the list below';
    }
  },

  isPresentAndEmailsIsEmpty(value, form) {
    if (!isValuePresent(value) && isEmpty(form.emails)) {
      return 'This is required';
    }
  }
};

const serverify = ({ emails }) => ({ emails });

const Tag = ({ email, onRemove, error }) => (
  <div className={getClassNames('tag', { error })}>
    {email}
    <CloseCircleIcon onClick={() => onRemove(email)} />
  </div>
);

const renderTags = (emails, onRemove) =>
  emails.map((email) => <Tag key={email} email={email} onRemove={onRemove} />);

const InviteNewAdmin = ({ onInvite }) => {
  const emailRef = createRef();

  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newForm) => setForm({ ...form, ...newForm });

  const onRemoveEmail = (emailToRemove) => {
    handleChange({ emails: form?.emails.filter((email) => email !== emailToRemove) });
  };

  const onAddEmail = (e) => {
    e.preventDefault();
    if (form?.messages?.email) {
      return;
    }

    const { emails, email } = form;
    handleChange({ emails: [...(emails ?? []), email], email: '' });
    emailRef.current.value = '';
  };

  const handleSubmit = () => {
    const payload = serverify(form);
    onInvite(payload);
  };

  return (
    <section className="invite-admin">
      <form className="invite-admin__search" onSubmit={onAddEmail} role="form">
        <Input
          inputRef={emailRef}
          name="email"
          type="email"
          label="Enter the email address of the admin/s you would like to invite and click add or enter to apply"
          placeholder="example@eventhub.co.za"
          action="Add"
          onActionClick={onAddEmail}
          onChange={handleChange}
          value={form?.email}
          error={form?.messages?.email}
        />
      </form>
      <div className="invite-admin__emails" role="table">
        {!isEmpty(form?.emails) && renderTags(form?.emails, onRemoveEmail)}
      </div>
      <div className="invite-admin__action">
        <Button disabled={!!form?.messages?.emails} label="Invite" onClick={handleSubmit} />
      </div>
    </section>
  );
};

export default InviteNewAdmin;
