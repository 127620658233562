import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { completeProfile } from '../../../actions/auth/auth.actions';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import CompleteProfile from '../../../components/auth/complete-profile/CompleteProfile';

const CompleteProfileContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token } = useParams();

  const onCompleteProfile = (payload) => {
    dispatch(completeProfile(token, payload, navigate));
  };

  return (
    <AuthLayout title="Complete Profile" subtitle="We just need a few more details" noLogo>
      <CompleteProfile onCompleteProfile={onCompleteProfile} />
    </AuthLayout>
  );
};

export default CompleteProfileContainer;
