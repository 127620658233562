export const LOAD_ORGANISATIONS = '[ORGANISATION_STORE] Load organisations';
export const SET_ORGANISATIONS = '[ORGANISATION_STORE] Set organisations';
export const loadOrganisations = (onComplete) => ({
  type: LOAD_ORGANISATIONS,
  onComplete
});

export const LOAD_ORGANISATION = '[ORGANISATION_STORE] Load organisation';
export const SET_ORGANISATION = '[ORGANISATION_STORE] Set organisation';
export const loadOrganisation = (id, onComplete) => ({
  type: LOAD_ORGANISATION,
  id,
  onComplete
});

export const REMOVE_ORGANISATION = '[ORGANISATION_STORE] Remove Organisation';
export const removeOrganisation = (id) => ({
  type: REMOVE_ORGANISATION,
  id
});
