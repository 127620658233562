import { getHttpDeleteOptions, getHttpGetOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST } from './index';

const getEventsEndpoint = () => `${API_HOST}/events`;
export const getLoadEventsRequest = () => [
  getEventsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getEventEndpoint = (id) => `${API_HOST}/events/${id}`;
export const getLoadEventRequest = (id) => [
  getEventEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getRemoveEventRequest = (id) => [
  getEventEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
