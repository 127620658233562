import { ReactComponent as HomeIcon } from '../../../icons/home-icon.svg';
import { ReactComponent as EventIcon } from '../../../icons/events-icon.svg';
import { ReactComponent as UsersIcon } from '../../../icons/users-icon.svg';
import { ReactComponent as OrganisationsIcon } from '../../../icons/organizations-icon.svg';
import { ReactComponent as CompetitionsIcon } from '../../../icons/competitions-icon.svg';
import { ReactComponent as VerificationsIcon } from '../../../icons/verifications-icon.svg';
import { ReactComponent as PaymentsIcon } from '../../../icons/invoice-icon.svg';
import { ReactComponent as SportIcon } from '../../../icons/sport-icon.svg';

export const sidebarItems = [
  {
    title: 'Home',
    link: '/dashboard',
    icon: <HomeIcon />
  },
  {
    title: 'Competitions',
    link: '/competitions',
    icon: <CompetitionsIcon />
  },
  {
    title: 'Events',
    link: '/events',
    icon: <EventIcon />
  },
  {
    title: 'Users',
    link: '/users',
    icon: <UsersIcon />
  },
  {
    title: 'Organisations',
    link: '/organisations',
    icon: <OrganisationsIcon />
  },
  {
    title: 'Verifications',
    link: '/verifications',
    icon: <VerificationsIcon />
  },
  {
    title: 'Payments',
    link: '/payments',
    icon: <PaymentsIcon />
  },
  {
    title: 'Sports',
    link: '/sports',
    icon: <SportIcon />
  }
];
