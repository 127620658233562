import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SnackBar } from '@neslotech/eventhub-ui-kit';

import { removeSystemNotice } from './actions/system/system.actions';

import AuthContainer from './containers/auth/Auth.container';
import AdminContainer from './containers/admin/Admin.container';
import AuthProvider from './providers/Auth.provider';

const style = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};

const App = () => {
  const dispatch = useDispatch();
  const notices = useSelector(({ system_store }) => system_store.notices);

  const handleCloseNotice = (noticeId) => {
    dispatch(removeSystemNotice(noticeId));
  };

  return (
    <div style={style}>
      <AuthProvider>
        <AuthContainer />
        <AdminContainer />
      </AuthProvider>
      <SnackBar notices={notices} onCloseNotice={handleCloseNotice} />
    </div>
  );
};

export default App;
