export const tableHeaders = [
  { Header: '', accessor: 'image', disableSortBy: true },
  { Header: 'Organisation', accessor: 'name' },
  { Header: 'Email Address', accessor: 'email' },
  { Header: 'Created By', accessor: 'createdBy' },
  { Header: 'Status', accessor: 'status' },
  { Header: '', accessor: 'action', disableSortBy: true }
];

export const tableModifiers = {
  action: {
    actions: true
  }
};

export const getOrganisationInitials = ({ name }) => {
  return name
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('');
};
