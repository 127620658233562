import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, PageLayout, Tabs } from '@neslotech/eventhub-ui-kit';

import {
  INACTIVE_STATUS,
  REJECTED_STATUS,
  REQUESTED_CHANGE_STATUS
} from '../../../tools/constants';

import { loadVerifications } from '../../../actions/verification/verification.actions';

import { ReactComponent as InboxIcon } from '../../../icons/inbox-icon.svg';
import { ReactComponent as WritingIcon } from '../../../icons/writing-icon.svg';
import { ReactComponent as CloseBoxIcon } from '../../../icons/close-box-icon.svg';

import AdminLayout from '../../../components/layout/admin/AdminLayout';
import VerificationsList from '../../../components/verifications/VerificationsList';
import FilterLayout from '../../../components/layout/filter/FilterLayout';

const tabs = (filter) => [
  {
    key: INACTIVE_STATUS,
    label: 'Awaiting Verification',
    icon: <InboxIcon />,
    onClick: () => filter(INACTIVE_STATUS)
  },
  {
    key: REQUESTED_CHANGE_STATUS,
    label: 'Changes Requested',
    icon: <WritingIcon />,
    onClick: () => filter(REQUESTED_CHANGE_STATUS)
  },
  {
    key: REJECTED_STATUS,
    label: 'Rejected',
    icon: <CloseBoxIcon />,
    onClick: () => filter(REJECTED_STATUS)
  }
];

const VerificationsContainer = () => {
  const dispatch = useDispatch();

  const verifications = useSelector(({ verification_store }) => verification_store.verifications);
  const stateLoading = useSelector(({ verification_store }) => verification_store.loading);

  const [filteredVerifications, setFilteredVerifications] = useState([]);
  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadVerifications(() => setLoading(false)));
  }, [dispatch]);

  useEffect(
    () => {
      filterVerifications();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [verifications]
  );

  const filterVerifications = (type) => {
    switch (type) {
      case REJECTED_STATUS:
        setFilteredVerifications(verifications.filter((item) => item.status === REJECTED_STATUS));
        break;
      case REQUESTED_CHANGE_STATUS:
        setFilteredVerifications(
          verifications.filter((item) => item.status === REQUESTED_CHANGE_STATUS)
        );
        break;
      case INACTIVE_STATUS:
      default:
        setFilteredVerifications(verifications.filter((item) => item.status === INACTIVE_STATUS));
        break;
    }
  };

  return (
    <AdminLayout>
      <PageLayout title="Verification Requests">
        <FilterLayout searchPlaceholder="Search requests...">
          <Tabs tabs={tabs(filterVerifications)} />
          {loading && <Loader filled />}
          {!loading && <VerificationsList verifications={filteredVerifications} />}
        </FilterLayout>
      </PageLayout>
    </AdminLayout>
  );
};

export default VerificationsContainer;
