import { SET_PAYMENT, SET_PAYMENTS } from '../../actions/entry/entry.actions';

export const initialState = {
  payments: [],
  payment: undefined
};

export const entryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENTS:
      const { payments } = action;
      return {
        ...state,
        payments
      };
    case SET_PAYMENT:
      const { payment } = action;
      return {
        ...state,
        payment
      };
    default:
      return state;
  }
};
