import { SET_EVENT, SET_EVENTS } from '../../actions/event/event.actions';

export const initialState = {
  events: [],
  event: undefined
};

export const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENTS:
      const { events } = action;
      return {
        events
      };
    case SET_EVENT:
      const { event } = action;
      return {
        event
      };
    default:
      return state;
  }
};
