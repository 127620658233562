import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getLoadOrganisationRequest,
  getLoadOrganisationsRequest,
  getRemoveOrganisationRequest
} from '../../tools/api/organisation.endpoints';

import { addSystemNotice } from '../../actions/system/system.actions';
import {
  LOAD_ORGANISATION,
  LOAD_ORGANISATIONS,
  loadOrganisations,
  REMOVE_ORGANISATION,
  SET_ORGANISATION,
  SET_ORGANISATIONS
} from '../../actions/organisation/organisation.actions';

export function* performLoadOrganisations({ onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadOrganisationsRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set organisations on the state
    yield put({ type: SET_ORGANISATIONS, organisations: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to load organisations.', SNACK_CRITICAL)
    );
  }

  yield call(onComplete);
}

export function* watchForLoadOrganisationsRequest() {
  yield takeLatest(LOAD_ORGANISATIONS, performLoadOrganisations);
}

export function* performLoadOrganisation({ id, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadOrganisationRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set organisations on the state
    yield put({ type: SET_ORGANISATION, organisation: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to load organisation.', SNACK_CRITICAL)
    );
  }

  yield call(onComplete);
}

export function* watchForLoadOrganisationRequest() {
  yield takeLatest(LOAD_ORGANISATION, performLoadOrganisation);
}

export function* performRemoveOrganisation({ id }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveOrganisationRequest(id);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('The organisation has been removed.', SNACK_SUCCESS));

    // reload organisations
    yield put(loadOrganisations());
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to remove the organisation.', SNACK_CRITICAL)
    );
  }
}

export function* watchForRemoveOrganisationRequest() {
  yield takeLatest(REMOVE_ORGANISATION, performRemoveOrganisation);
}

export default function* organisationSaga() {
  yield all([
    watchForLoadOrganisationsRequest(),
    watchForLoadOrganisationRequest(),
    watchForRemoveOrganisationRequest()
  ]);
}
