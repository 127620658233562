import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getAcceptVerificationRequest,
  getLoadVerificationsRequest,
  getRejectVerificationRequest,
  getRequestChangeVerificationRequest
} from '../../tools/api/verification.endpoints';

import {
  ACCEPT_VERIFICATION,
  LOAD_VERIFICATIONS,
  REJECT_VERIFICATION,
  REQUEST_CHANGE_VERIFICATION,
  SET_VERIFICATIONS
} from '../../actions/verification/verification.actions';
import { addSystemNotice } from '../../actions/system/system.actions';

export function* performLoadVerifications({ onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadVerificationsRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set verifications on the state
    yield put({ type: SET_VERIFICATIONS, verifications: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to load verifications.', SNACK_CRITICAL)
    );
  }

  yield call(onComplete);
}

export function* watchForLoadVerificationsRequest() {
  yield takeLatest(LOAD_VERIFICATIONS, performLoadVerifications);
}

export function* performAcceptVerification({ id, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getAcceptVerificationRequest(id);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('The organisation has been verified successfully.', SNACK_SUCCESS));

    // reload verifications
    yield call(navigate, '/verifications');
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to accept verification.', SNACK_CRITICAL)
    );
  }
}

export function* watchForAcceptVerificationRequest() {
  yield takeLatest(ACCEPT_VERIFICATION, performAcceptVerification);
}

export function* performRejectVerification({ id, payload, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRejectVerificationRequest(id, payload);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('The organisation has been rejected successfully.', SNACK_SUCCESS));

    // reload verifications
    yield call(navigate, '/verifications');
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to reject verification.', SNACK_CRITICAL)
    );
  }
}

export function* watchForRejectVerificationRequest() {
  yield takeLatest(REJECT_VERIFICATION, performRejectVerification);
}

export function* performRequestChangeVerification({ id, payload, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRequestChangeVerificationRequest(id, payload);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Change Request has been submitted successfully.', SNACK_SUCCESS));

    // reload verifications
    yield call(navigate, '/verifications');
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        response?.data?.error ?? 'Failed to request change for the verification.',
        SNACK_CRITICAL
      )
    );
  }
}

export function* watchForRequestChangeVerificationRequest() {
  yield takeLatest(REQUEST_CHANGE_VERIFICATION, performRequestChangeVerification);
}

export default function* verificationSaga() {
  yield all([
    watchForLoadVerificationsRequest(),
    watchForAcceptVerificationRequest(),
    watchForRejectVerificationRequest(),
    watchForRequestChangeVerificationRequest()
  ]);
}
