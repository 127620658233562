import React from 'react';

import { Button, ButtonGroup } from '@neslotech/eventhub-ui-kit';

import './remove-sport.scss';

const RemoveSport = ({ sport, onConfirmClick, onCancelClick }) => (
  <section className="remove-sport">
    <div className="remove-sport__message">
      <p>
        Are you sure you want to remove <span>{sport?.name ?? 'this sport'}</span>?
      </p>
      <p>All of the sports data will be permanently deleted.</p>
    </div>
    <ButtonGroup>
      <Button label="Confirm" onClick={onConfirmClick} />
      <Button label="Cancel" onClick={onCancelClick} secondary />
    </ButtonGroup>
  </section>
);

export default RemoveSport;
