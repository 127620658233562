import React from 'react';

import { Form, FormAction, FormRow, Input, PasswordInput } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './complete-profile.scss';

const rules = {
  validates: {
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    password: ['isPresent'],
    passwordConfirm: ['isPresent', 'arePasswordsEqual']
  },

  arePasswordsEqual(value, form) {
    if (value !== form?.password) {
      return 'Passwords do not match';
    }
  }
};

const serverify = ({ firstName, lastName, password, passwordConfirm }) => ({
  first_name: firstName,
  last_name: lastName,
  password,
  password_confirm: passwordConfirm
});

const CompleteProfile = ({ onCompleteProfile }) => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleSubmit = () => {
    const payload = serverify(form);
    onCompleteProfile(payload);
  };

  return (
    <section className="complete-profile">
      <Form>
        <FormRow>
          <Input
            name="firstName"
            label="First Name"
            placeholder="First name"
            value={form?.firstName}
            error={form?.messages?.firstName}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Input
            name="lastName"
            label="Last Name"
            placeholder="Last name"
            value={form?.lastName}
            error={form?.messages?.lastName}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <PasswordInput
            name="password"
            label="Password"
            placeholder="Enter your password"
            value={form?.password}
            error={form?.messages?.password}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <PasswordInput
            name="passwordConfirm"
            label="Confirm Password"
            placeholder="Confirm password"
            value={form?.passwordConfirm}
            error={form?.messages?.passwordConfirm}
            onChange={handleChange}
          />
        </FormRow>
        <FormAction label="Save" onClick={handleSubmit} disabled={!form?.valid} />
      </Form>
    </section>
  );
};

export default CompleteProfile;
