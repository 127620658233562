import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { SNACK_CRITICAL } from '@neslotech/utils';

import { getAuthTokenCookie } from '../tools/auth.util';

import { addSystemNotice } from '../actions/system/system.actions';

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const token = getAuthTokenCookie();

  if (!token) {
    dispatch(addSystemNotice('You are not authorized to view this page.', SNACK_CRITICAL));
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
