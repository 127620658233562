import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Loader, PageLayout, RadioGroup } from '@neslotech/eventhub-ui-kit';
import { noOp } from '@neslotech/utils';

import { loadUsers } from '../../../actions/user/user.actions';
import { loadProfile } from '../../../actions/profile/profile.actions';

import { ReactComponent as ChevronRightIcon } from '../../../icons/chevron-right-icon.svg';

import AdminLayout from '../../../components/layout/admin/AdminLayout';
import FilterLayout from '../../../components/layout/filter/FilterLayout';
import UsersList from '../../../components/users/UsersList';
import InviteNewAdminContainer from './invite/InviteNewAdmin.container';

const renderActions = (onClick) => <Button label="Invite Admin" onClick={onClick} />;

const menuItems = (handleChange, filters) => [
  {
    text: 'Status',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        onChange={handleChange}
        value={filters?.status}
        name="status"
        items={[
          { label: 'Inactive', value: 'inactive' },
          { label: 'Active', value: 'active' }
        ]}
      />
    )
  },
  {
    text: 'Role',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        onChange={handleChange}
        value={filters?.role}
        name="role"
        items={[
          { label: 'Admin', value: 'admin' },
          { label: 'Organizer', value: 'organizer' },
          { label: 'Participant', value: 'participant' }
        ]}
      />
    )
  }
];

const UsersContainer = () => {
  const dispatch = useDispatch();

  const profile = useSelector(({ profile_store }) => profile_store.profile);
  const users = (useSelector(({ user_store }) => user_store.users) ?? []).filter((user) =>
    !!profile ? user.id !== profile.id : true
  );
  const stateLoading = useSelector(({ user_store }) => user_store.loading);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadProfile());
    dispatch(loadUsers(() => setLoading(false)));
  }, [dispatch]);

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <AdminLayout>
        <PageLayout title="User Management">
          <FilterLayout
            menuItems={menuItems}
            actions={renderActions(openModal)}
            searchPlaceholder="Search for a user..."
          >
            {loading && <Loader filled />}
            {!loading && <UsersList users={users} />}
          </FilterLayout>
        </PageLayout>
      </AdminLayout>
      <InviteNewAdminContainer show={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default UsersContainer;
