import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { addSystemNotice } from '../../actions/system/system.actions';
import {
  LOAD_COMPETITION,
  LOAD_COMPETITIONS,
  loadCompetitions,
  REMOVE_COMPETITION,
  SET_COMPETITION,
  SET_COMPETITIONS
} from '../../actions/competition/competition.actions';
import {
  getLoadCompetitionRequest,
  getLoadCompetitionsRequest,
  getRemoveCompetitionRequest
} from '../../tools/api/competition.endpoints';

export function* performLoadCompetitions({ onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadCompetitionsRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set competitions on the state
    yield put({ type: SET_COMPETITIONS, competitions: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to load competitions.', SNACK_CRITICAL)
    );
  }

  yield call(onComplete);
}

export function* watchForLoadCompetitionsRequest() {
  yield takeLatest(LOAD_COMPETITIONS, performLoadCompetitions);
}

export function* performLoadCompetition({ id, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadCompetitionRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set competitions on the state
    yield put({ type: SET_COMPETITION, competition: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to load competition.', SNACK_CRITICAL)
    );
  }

  yield call(onComplete);
}

export function* watchForLoadCompetitionRequest() {
  yield takeLatest(LOAD_COMPETITION, performLoadCompetition);
}

export function* performRemoveCompetition({ id, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveCompetitionRequest(id);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('The competition has been removed.', SNACK_SUCCESS));

    // reload organisations
    yield put(loadCompetitions());

    yield call(navigate, '/competitions');
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to remove the competition.', SNACK_CRITICAL)
    );
  }
}

export function* watchForRemoveCompetitionRequest() {
  yield takeLatest(REMOVE_COMPETITION, performRemoveCompetition);
}

export default function* competitionSaga() {
  yield all([
    watchForLoadCompetitionsRequest(),
    watchForLoadCompetitionRequest(),
    watchForRemoveCompetitionRequest()
  ]);
}
