import React from 'react';
import { number, shape, string } from 'prop-types';

import { ORGANIZER_ROLE } from '@neslotech/eventhub-ui-kit';

import OrganisationBadgesSection from './badges/OrganisationBadges';
import AccountDetailsSection from './account/AccountDetails';
import PersonalDetailsSection from './personal/PersonalDetails';

import './user-details.scss';

const UserDetails = ({ user }) => (
  <section className="user-details">
    <PersonalDetailsSection user={user} />
    <section className="user-details__split">
      {user.role === ORGANIZER_ROLE && (
        <OrganisationBadgesSection organisations={user.organisations} />
      )}
      <AccountDetailsSection user={user} />
    </section>
  </section>
);

UserDetails.propTypes = {
  user: shape({
    id: number.isRequired,
    first_name: string,
    last_name: string,
    role: string.isRequired
  }).isRequired
};

export default UserDetails;
