import { getHttpGetOptions, getHttpPostOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST } from './index';

const getEntriesEndpoint = () => `${API_HOST}/entries`;
export const getLoadEntriesRequest = () => [
  getEntriesEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getEntryEndpoint = (id) => `${API_HOST}/entries/${id}`;
export const getLoadEntryRequest = (id) => [
  getEntryEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getConfirmEntryEndpoint = (id) => `${API_HOST}/entries/${id}/confirm`;
export const getConfirmEntryRequest = (id, payload) => [
  getConfirmEntryEndpoint(id),
  getHttpPostOptions(payload, getAuthHeaders())
];
