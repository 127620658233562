import React from 'react';

import { Card, ProfileImage } from '@neslotech/eventhub-ui-kit';

import { getProfileInitials } from '../../../../containers/admin/users/users.helpers';

import Badge from '../../../badge/Badge';

import './organisers.scss';

const OrganisersSection = ({ organisation }) => (
  <Card>
    <section className="organisers">
      <header>
        <h4>Organisers</h4>
      </header>
      <section className="organisers__badges">
        {organisation.organisers.map((organiser) => (
          <Badge
            key={organiser.id}
            image={
              <ProfileImage
                fitted
                small
                imgSrc={organiser.image}
                initials={getProfileInitials(organiser)}
              />
            }
          />
        ))}
      </section>
    </section>
  </Card>
);

export default OrganisersSection;
