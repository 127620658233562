export const LOAD_SPORTS = '[SPORT_STORE] Load sports';
export const SET_SPORTS = '[SPORT_STORE] Set sports';
export const loadSports = (onComplete) => ({
  type: LOAD_SPORTS,
  onComplete
});

export const ADD_SPORT = '[SPORT_STORE] Add sport';
export const addSport = (payload, navigate) => ({
  type: ADD_SPORT,
  payload,
  navigate
});

export const LOAD_SPORT = '[SPORT_STORE] Load sport';
export const SET_SPORT = '[SPORT_STORE] Set sport';
export const loadSport = (id, onComplete) => ({
  type: LOAD_SPORT,
  id,
  onComplete
});

export const UPDATE_SPORT = '[SPORT_STORE] Update sport';
export const updateSport = (id, payload, navigate, onComplete) => ({
  type: UPDATE_SPORT,
  id,
  payload,
  navigate,
  onComplete
});

export const REMOVE_SPORT = '[SPORT_STORE] Remove sport';
export const removeSport = (id, navigate) => ({
  type: REMOVE_SPORT,
  id,
  navigate
});
