import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import {
  getLoadEventRequest,
  getLoadEventsRequest,
  getRemoveEventRequest
} from '../../tools/api/event.endpoints';

import { addSystemNotice } from '../../actions/system/system.actions';
import {
  LOAD_EVENT,
  LOAD_EVENTS,
  loadEvents,
  REMOVE_EVENT,
  SET_EVENT,
  SET_EVENTS
} from '../../actions/event/event.actions';

export function* performLoadEvents({ onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadEventsRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set events on the state
    yield put({ type: SET_EVENTS, events: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to load events.', SNACK_CRITICAL));
  }

  yield call(onComplete);
}

export function* watchForLoadEventsRequest() {
  yield takeLatest(LOAD_EVENTS, performLoadEvents);
}

export function* performLoadEvent({ id, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadEventRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set events on the state
    yield put({ type: SET_EVENT, event: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to load event.', SNACK_CRITICAL));
  }

  yield call(onComplete);
}

export function* watchForLoadEventRequest() {
  yield takeLatest(LOAD_EVENT, performLoadEvent);
}

export function* performRemoveEvent({ id }) {
  try {
    const [endpoint, requestOptions] = getRemoveEventRequest(id);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('The event has been removed.', SNACK_SUCCESS));

    yield put(loadEvents());
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to remove the event.', SNACK_CRITICAL)
    );
  }
}

export function* watchForRemoveEventRequest() {
  yield takeLatest(REMOVE_EVENT, performRemoveEvent);
}

export default function* eventSaga() {
  yield all([
    watchForLoadEventsRequest(),
    watchForLoadEventRequest(),
    watchForRemoveEventRequest()
  ]);
}
