import { getHttpDeleteOptions, getHttpGetOptions, getHttpPostOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST } from './index';

const getUsersEndpoint = () => `${API_HOST}/users`;
export const getLoadUsersRequest = () => [getUsersEndpoint(), getHttpGetOptions(getAuthHeaders())];

const getUserEndpoint = (id) => `${API_HOST}/users/${id}`;
export const getLoadUserRequest = (id) => [
  getUserEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getRemoveUserRequest = (id) => [
  getUserEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];

export const getInviteAdminsRequest = (email) => [
  getUsersEndpoint(),
  getHttpPostOptions({ email, role: 'admin' }, getAuthHeaders())
];
