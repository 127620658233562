import React from 'react';

import { Button, ButtonGroup } from '@neslotech/eventhub-ui-kit';

import './remove-organisation.scss';

const RemoveOrganisation = ({ organisation, onConfirmClick, onCancelClick }) => {
  return (
    <section className="remove-organisation">
      <div className="remove-organisation__message">
        <p>
          Are you sure you want to remove <span>{organisation?.name}</span>?
        </p>
      </div>
      <ButtonGroup>
        <Button label="Confirm" onClick={onConfirmClick} />
        <Button label="Cancel" onClick={onCancelClick} secondary />
      </ButtonGroup>
    </section>
  );
};

export default RemoveOrganisation;
