import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { filterElement, ProfileImage, searchElement, Table } from '@neslotech/eventhub-ui-kit';
import { capitalise } from '@neslotech/utils';

import {
  getProfileInitials,
  tableHeaders,
  tableModifiers
} from '../../containers/admin/users/users.helpers';

import { useFilters } from '../../hooks/useFilters';

import { ReactComponent as CloseCircleHollowIcon } from '../../icons/close-circle-hollow-icon.svg';

import RemoveUserContainer from '../../containers/admin/users/remove/RemoveUser.container';

import './users-list.scss';

const formify = (users, onSelectRow, setUser, search, filters) =>
  users
    .filter((user) => searchElement(user.full_name, search) || searchElement(user.email, search))
    .filter((user) => filterElement(user, filters))
    .map(({ id, full_name, first_name, last_name, email, status, role, image }) => ({
      id,
      firstName: first_name,
      lastName: last_name,
      email,
      status: capitalise(status),
      role: capitalise(role),
      onClick: () => onSelectRow(id),
      action: (
        <CloseCircleHollowIcon
          className="table__body-action--danger"
          onClick={(e) => {
            e.stopPropagation();
            setUser({ id, full_name });
          }}
        />
      ),
      profileImage: (
        <ProfileImage
          imgSrc={image}
          initials={getProfileInitials({ first_name, last_name })}
          small
        />
      )
    }));

const UsersList = ({ users }) => {
  const navigate = useNavigate();

  const { search, filters, filtering, searching, setFilterDefaults } = useFilters();
  const [userToDelete, setUserToDelete] = useState();

  const emptyMessage = useMemo(() => {
    return filtering || searching
      ? 'We can’t find the person you are looking for.'
      : 'This table is empty. There are no users yet.';
  }, [filtering, searching]);

  useEffect(() => {
    setFilterDefaults({ status: '', role: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectRow = (id) => {
    navigate(`${id}`);
  };

  return (
    <section className="users-list">
      <Table
        cols={tableHeaders}
        columnModifiers={tableModifiers}
        rowData={formify(users, onSelectRow, setUserToDelete, search, filters)}
        emptyTitle="No One Is Here"
        emptySubtitle={emptyMessage}
      />
      <RemoveUserContainer user={userToDelete} show={userToDelete} setShowModal={setUserToDelete} />
    </section>
  );
};

export default UsersList;
