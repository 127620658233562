import React from 'react';

import {
  Button,
  ButtonGroup,
  Form,
  FormAction,
  FormRow,
  TextArea
} from '@neslotech/eventhub-ui-kit';

import { useFormState } from '@neslotech/hooks';

import './request-change-verification.scss';

const rules = {
  validates: {
    message: ['isPresent']
  }
};

const serverify = ({ message }) => ({ message });

const RequestChangeVerification = ({ onConfirmClick, onCancelClick }) => {
  const [form, setForm] = useFormState(undefined, rules);

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleConfirm = () => {
    const payload = serverify(form);
    onConfirmClick(payload);
  };

  return (
    <section className="request-change-verification">
      <p className="request-change-verification__message">
        Please elaborate on the content you wish the user to change and justify why you want them to
        change it.
      </p>
      <Form>
        <FormRow>
          <TextArea
            name="message"
            placeholder="Message"
            label="Message:"
            onChange={handleChange}
            value={form?.message}
            error={form?.messages?.message}
          />
        </FormRow>
        <ButtonGroup>
          <FormAction label="Confirm" onClick={handleConfirm} disabled={!form?.valid} />
          <Button label="Cancel" secondary onClick={onCancelClick} />
        </ButtonGroup>
      </Form>
    </section>
  );
};

export default RequestChangeVerification;
