export const LOAD_EVENTS = '[EVENT_STORE] Load events';
export const SET_EVENTS = '[EVENT_STORE] Set events';
export const loadEvents = (onComplete) => ({
  type: LOAD_EVENTS,
  onComplete
});

export const LOAD_EVENT = '[EVENT_STORE] Load event';
export const SET_EVENT = '[EVENT_STORE] Set event';
export const loadEvent = (id, onComplete) => ({
  type: LOAD_EVENT,
  id,
  onComplete
});

export const REMOVE_EVENT = '[EVENT_STORE] Remove event';
export const removeEvent = (id) => ({
  type: REMOVE_EVENT,
  id
});
