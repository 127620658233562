import { getHttpGetOptions, getHttpPutOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST } from './index';

const getProfileEndpoint = () => `${API_HOST}/profile`;
export const getLoadProfileRequest = () => [
  getProfileEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateProfileRequest = (profile) => [
  getProfileEndpoint(),
  getHttpPutOptions(profile, getAuthHeaders())
];

const getDashboardEndpoint = () => `${API_HOST}/dashboard/admin`;
export const getLoadDashboardRequest = () => [
  getDashboardEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];
