export const tableHeaders = [
  { Header: '', accessor: 'image', disableSortBy: true },
  { Header: 'Organization', accessor: 'name' },
  { Header: 'Email Address', accessor: 'emailAddress' },
  { Header: 'Contact Number', accessor: 'contactNumber' },
  { Header: 'Physical Address', accessor: 'address' },
  { Header: 'Created By', accessor: 'createdBy' },
  { Header: '', accessor: 'action', disableSortBy: true }
];

export const tableModifiers = {
  action: {
    actions: true
  }
};

export const getProfileInitials = ({ name }) => {
  return name
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('');
};
