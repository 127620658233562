import { getHttpGetOptions, getHttpPutOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST } from './index';

const getVerificationsEndpoint = () => `${API_HOST}/verifications`;
export const getLoadVerificationsRequest = () => [
  getVerificationsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getAcceptVerificationEndpoint = (id) => `${API_HOST}/verifications/${id}/accept`;
export const getAcceptVerificationRequest = (id) => [
  getAcceptVerificationEndpoint(id),
  getHttpPutOptions(undefined, getAuthHeaders())
];

const getRejectVerificationEndpoint = (id) => `${API_HOST}/verifications/${id}/reject`;
export const getRejectVerificationRequest = (id, payload) => [
  getRejectVerificationEndpoint(id),
  getHttpPutOptions(payload, getAuthHeaders())
];

const getRequestChangeVerificationEndpoint = (id) =>
  `${API_HOST}/verifications/${id}/request_change`;
export const getRequestChangeVerificationRequest = (id, payload) => [
  getRequestChangeVerificationEndpoint(id),
  getHttpPutOptions(payload, getAuthHeaders())
];
