import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Card,
  Form,
  FormRow,
  InfoCard,
  Input,
  RadioGroup,
  Scroller,
  Table,
  TextArea
} from '@neslotech/eventhub-ui-kit';

import { tableHeaders } from './competition-details.helpers';
import { ReactComponent as CalendarIcon } from '../../../icons/calendar-icon.svg';

import ContextDetails from '../../context/ContextDetails';
import DetailsSection from '../../section/DetailsSection';
import Select from '../../select/Select';

import './competition-details.scss';

const CompetitionDetailsSection = ({ competition }) => (
  <section className="competition-details-section">
    <Form>
      <FormRow fluid>
        <Input
          placeholder="Competition Name"
          label="Competition Name"
          value={competition.name}
          disabled
        />
        <Select
          placeholder="Type of Competition"
          label="Type of Competition"
          value={competition.competition_type}
          disabled
        />
      </FormRow>
      <FormRow fluid>
        <Select placeholder="Sport" label="Sport" value={competition.sport} disabled />
        <Input
          placeholder="Start and End Date"
          label="Start and End Date"
          action={<CalendarIcon />}
          value={`${competition.start_date} - ${competition.end_date}`}
          disabled
        />
      </FormRow>
      <FormRow fluid>
        <RadioGroup
          label="Paid or Free Competition"
          disabled
          fluid
          name="paid"
          value={competition.paid}
          items={[
            { label: 'Paid', value: true },
            { label: 'Free', value: false }
          ]}
        />
        <RadioGroup
          label="Regulated Competition"
          disabled
          fluid
          name="regulated"
          value={competition.regulated}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
      </FormRow>
      <FormRow fluid>
        <RadioGroup
          label="You are using your supplied banking details"
          disabled
          fluid
          name="supplied_banking_details"
          value={competition.supplied_banking_details}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
        <RadioGroup
          label="National or Regional Competition"
          disabled
          fluid
          name="national"
          value={competition.national}
          items={[
            { label: 'National', value: true },
            { label: 'Regional', value: false }
          ]}
        />
      </FormRow>
      <FormRow>
        <RadioGroup
          label="Do you want participants entries to be visible on the landing page?"
          disabled
          fluid
          name="visible_entries"
          value={competition.visible_entries}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
      </FormRow>
    </Form>
  </section>
);

const DescriptionSection = ({ competition }) => (
  <DetailsSection title="Competition Description">
    <section className="description-section">
      <Form>
        <FormRow>
          <TextArea
            placeholder="Enter Competition Description"
            value={competition.description}
            disabled
          />
        </FormRow>
      </Form>
    </section>
  </DetailsSection>
);

const ClassesSection = ({ competition }) => (
  <DetailsSection title="Classes in the Competition">
    <section className="classes-section">
      <Table cols={tableHeaders} rowData={competition.classes} />
    </section>
  </DetailsSection>
);

const mapCards = (events, onOpenEvent) =>
  events.map((event) => (
    <InfoCard
      onClick={() => onOpenEvent(event.id)}
      key={event.id}
      name={event.name}
      description={event.description}
      date={event.start_date}
      image={<img src={event.image} alt={event.name} />}
    />
  ));

const EventsSection = ({ competition, onOpenEvent }) => (
  <section className="events-section">
    <Card>
      <p>Events in the competition</p>
      <Scroller items={mapCards(competition.events, onOpenEvent)} />
    </Card>
  </section>
);

const CompetitionDetails = ({ competition, onDelete }) => {
  const navigate = useNavigate();

  const onOpenEvent = (id) => {
    navigate(`/events/${id}`);
  };

  return (
    <section className="competition-details">
      <ContextDetails
        context={competition}
        actions={[<Button key="remove" label="Remove Competition" danger onClick={onDelete} />]}
      />
      <section className="competition-details__details">
        <DetailsSection title="Competition Details">
          <CompetitionDetailsSection competition={competition} />
        </DetailsSection>
        <DescriptionSection competition={competition} />
        <ClassesSection competition={competition} />
        <EventsSection competition={competition} onOpenEvent={onOpenEvent} />
      </section>
    </section>
  );
};

export default CompetitionDetails;
