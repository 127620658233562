import { SET_USER, SET_USERS } from '../../actions/user/user.actions';

export const initialState = {
  users: [],
  user: undefined
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      const { users } = action;
      return {
        users
      };
    case SET_USER:
      const { user } = action;
      return {
        user
      };
    default:
      return state;
  }
};
