import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Sidebar } from '@neslotech/eventhub-ui-kit';
import { getClassNames, noOp } from '@neslotech/utils';

import { useAuth } from '../../../hooks/useAuth';

import { sidebarItems } from './sidebar.helpers';

import { ReactComponent as ProfileIcon } from '../../../icons/profile-icon.svg';
import { ReactComponent as CogIcon } from '../../../icons/cog-icon.svg';
import { ReactComponent as LogoutIcon } from '../../../icons/logout-icon.svg';

import './admin-layout.scss';

const dropdownItems = (navigate, auth) => [
  {
    text: 'My Profile',
    icon: <ProfileIcon />,
    onClick: () => navigate('/profile')
  },
  {
    text: 'Settings',
    icon: <CogIcon />,
    onClick: noOp
  },
  {
    text: 'Log Out',
    icon: <LogoutIcon />,
    onClick: auth?.onLogout ?? noOp
  },
  {
    text: 'About the app',
    bordered: true,
    onClick: noOp
  }
];

const AdminLayout = ({ children, gutterless }) => {
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <section className="admin-layout">
      <Sidebar items={sidebarItems} />
      <section className="admin-layout__content-section">
        <Header items={dropdownItems(navigate, auth)} />
        <div className={getClassNames('admin-layout__content', { gutterless })}>{children}</div>
      </section>
    </section>
  );
};

export default AdminLayout;
