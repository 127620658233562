import React from 'react';

import { Card, Form, FormRow, Input } from '@neslotech/eventhub-ui-kit';

import './account-details.scss';

const AccountDetails = ({ organisation }) => (
  <Card>
    <section className="organisation-account-details">
      <Form>
        <FormRow fluid>
          <Input
            placeholder="Example@eventhub.co.za"
            label="Email Address"
            value={organisation.email_address}
            disabled
          />
          <Input
            placeholder="Organisation Created By"
            label="Organisation Created By"
            value={organisation.created_by}
            disabled
          />
        </FormRow>
        <FormRow>
          <Input
            placeholder="Created On"
            label="Created On"
            value={new Date(organisation.created_at).toLocaleDateString()}
            disabled
          />
        </FormRow>
      </Form>
    </section>
  </Card>
);

export default AccountDetails;
