import { SET_DASHBOARD, SET_PROFILE } from '../../actions/profile/profile.actions';

export const initialState = {
  profile: undefined,
  dashboard: undefined
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      const { profile } = action;
      return {
        ...state,
        profile
      };
    case SET_DASHBOARD:
      const { dashboard } = action;
      return {
        ...state,
        dashboard
      };
    default:
      return state;
  }
};
