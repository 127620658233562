import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { removeSport } from '../../../../actions/sport/sport.actions';

import RemoveSport from '../../../../components/sports/remove/RemoveSport';

const RemoveSportContainer = ({ sport, show, setShowModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClose = () => setShowModal(false);

  const handleConfirm = () => {
    dispatch(removeSport(sport.id, navigate));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Sport">
            <RemoveSport sport={sport} onConfirmClick={handleConfirm} onCancelClick={onClose} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveSportContainer;
