import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  ADMIN_ROLE,
  Checkbox,
  Form,
  FormAction,
  FormRow,
  Input,
  PasswordInput
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './login.scss';

const rules = {
  validates: {
    email: ['isPresent', 'isEmail'],
    password: ['isPresent']
  }
};

const serverify = ({ email, password }) => ({
  email,
  password,
  role: ADMIN_ROLE
});

const Login = ({ onLogin }) => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    const credentials = serverify(form);
    onLogin(credentials, form.rememberMe, () => setLoading(false));
  };

  return (
    <section className="login">
      <Form>
        <FormRow>
          <Input
            name="email"
            label="Email Address"
            placeholder="Email Address"
            type="email"
            value={form?.email}
            error={form?.messages?.email}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <PasswordInput
            name="password"
            label="Password"
            placeholder="Password"
            value={form?.password}
            error={form?.messages?.password}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fluid>
          <Checkbox name="rememberMe" label="Remember me" onChange={handleChange} />
          <Link to="/password/forgot">
            <small className="login__forgot-password">Forgot Password?</small>
          </Link>
        </FormRow>
        <FormAction loading={loading} label="Login" onClick={onSubmit} disabled={!form?.valid} />
      </Form>
    </section>
  );
};

export default Login;
