import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL } from '@neslotech/utils';

import {
  getConfirmEntryRequest,
  getLoadEntriesRequest,
  getLoadEntryRequest
} from '../../tools/api/entry.endpoints';

import {
  CONFIRM_PAYMENT,
  LOAD_PAYMENT,
  LOAD_PAYMENTS,
  loadPayment,
  SET_PAYMENT,
  SET_PAYMENTS
} from '../../actions/entry/entry.actions';
import { addSystemNotice } from '../../actions/system/system.actions';

export function* performLoadPayments({ onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadEntriesRequest();

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_PAYMENTS, payments: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to load payments.', SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadPaymentsRequest() {
  yield takeLatest(LOAD_PAYMENTS, performLoadPayments);
}

export function* performLoadPayment({ id, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadEntryRequest(id);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_PAYMENT, payment: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to load payment.', SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadPaymentRequest() {
  yield takeLatest(LOAD_PAYMENT, performLoadPayment);
}

export function* performConfirmPayment({ id, payload }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getConfirmEntryRequest(id, payload);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('This payment was verified successfully.'));

    yield put(loadPayment(id));
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to confirm payment.', SNACK_CRITICAL)
    );
  }
}

export function* watchForConfirmPaymentRequest() {
  yield takeLatest(CONFIRM_PAYMENT, performConfirmPayment);
}

export default function* entrySaga() {
  yield all([
    watchForLoadPaymentsRequest(),
    watchForLoadPaymentRequest(),
    watchForConfirmPaymentRequest()
  ]);
}
