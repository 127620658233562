import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { loadOrganisation } from '../../../../actions/organisation/organisation.actions';

import OrganisationDetails from '../../../../components/organisations/details/OrganisationDetails';
import AdminLayout from '../../../../components/layout/admin/AdminLayout';

const OrganisationContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const organisation = useSelector(({ organisation_store }) => organisation_store.organisation);
  const stateLoading = useSelector(({ organisation_store }) => organisation_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadOrganisation(id, () => setLoading(false)));
  }, [dispatch, id]);

  return (
    <AdminLayout gutterless>
      <PageLayout title="Organisation Details" fixed back>
        {loading && <Loader filled />}
        {!loading && <OrganisationDetails organisation={organisation} />}
      </PageLayout>
    </AdminLayout>
  );
};

export default OrganisationContainer;
