import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { loadEvent } from '../../../../actions/event/event.actions';

import AdminLayout from '../../../../components/layout/admin/AdminLayout';
import EventDetails from '../../../../components/events/details/EventDetails';
import RemoveEventContainer from '../remove/RemoveEvent.container';

const EventContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const event = useSelector(({ event_store }) => event_store.event);
  const stateLoading = useSelector(({ event_store }) => event_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);
  const [eventToDelete, setEventToDelete] = useState(undefined);

  useEffect(() => {
    dispatch(loadEvent(id, () => setLoading(false)));
  }, [dispatch, id]);

  return (
    <>
      <AdminLayout gutterless>
        <PageLayout title="View Event Details" back fixed>
          {loading && <Loader filled />}
          {!loading && <EventDetails event={event} onDelete={() => setEventToDelete(event)} />}
        </PageLayout>
      </AdminLayout>
      <RemoveEventContainer
        event={eventToDelete}
        show={eventToDelete}
        setShowModal={setEventToDelete}
      />
    </>
  );
};

export default EventContainer;
