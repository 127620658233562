import { getHttpDeleteOptions, getHttpGetOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST } from './index';

const getCompetitionsEndpoint = () => `${API_HOST}/competitions`;
export const getLoadCompetitionsRequest = () => [
  getCompetitionsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getCompetitionEndpoint = (id) => `${API_HOST}/competitions/${id}`;
export const getLoadCompetitionRequest = (id) => [
  getCompetitionEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getRemoveCompetitionRequest = (id) => [
  getCompetitionEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
