import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getProfileInitials, ProfileImage, searchElement, Table } from '@neslotech/eventhub-ui-kit';
import { capitalise } from '@neslotech/utils';

import { useFilters } from '../../hooks/useFilters';

const formify = (payments, onSelectRow, search) =>
  payments
    .filter(
      (payment) =>
        searchElement(payment.created_by.full_name, search) ||
        searchElement(payment.reference_number, search)
    )
    .map(({ id, image, created_by, reference_number, status, type, updated_at }) => ({
      image: <ProfileImage imgSrc={image} initials={getProfileInitials(created_by)} small />,
      name: created_by.full_name,
      status: capitalise(status.replace('_', ' ')),
      type,
      updatedAt: updated_at,
      reference: reference_number,
      onClick: () => onSelectRow(id)
    }));

const tableHeaders = [
  { Header: '', accessor: 'image', disableSortBy: true },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Reference Number', accessor: 'reference' },
  { Header: 'Type', accessor: 'type' },
  { Header: 'Last Modified', accessor: 'updatedAt' },
  { Header: 'Status', accessor: 'status' }
];

const tableModifiers = {
  image: {
    thin: true
  }
};

const PaymentsList = ({ payments }) => {
  const navigate = useNavigate();

  const { search, searching } = useFilters();

  const emptyMessage = useMemo(() => {
    return searching
      ? 'We can’t find the payment verification that you are looking for.'
      : 'This table is empty. There are no payment verifications listed yet.';
  }, [searching]);

  const onSelectRow = (id) => {
    navigate(`${id}`);
  };

  return (
    <article className="payments-list">
      <Table
        cols={tableHeaders}
        columnModifiers={tableModifiers}
        rowData={formify(payments, onSelectRow, search)}
        emptyTitle="No Payment Verifications Found"
        emptySubtitle={emptyMessage}
      />
    </article>
  );
};

export default PaymentsList;
