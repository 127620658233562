export const LOAD_USERS = '[USER_STORE] Load users';
export const SET_USERS = '[USER_STORE] Set users';
export const loadUsers = (onComplete) => ({
  type: LOAD_USERS,
  onComplete
});

export const LOAD_USER = '[USER_STORE] Load user';
export const SET_USER = '[USER_STORE] Set user';
export const loadUser = (id, onComplete) => ({
  type: LOAD_USER,
  id,
  onComplete
});

export const INVITE_ADMINS = '[USER_STORE] Invite new admins';
export const inviteAdmins = (emails, onSuccess) => ({
  type: INVITE_ADMINS,
  emails,
  onSuccess
});

export const REMOVE_USER = '[USER_STORE] Remove user';
export const removeUser = (id) => ({
  type: REMOVE_USER,
  id
});
