import React from 'react';

import { Button, ButtonGroup } from '@neslotech/eventhub-ui-kit';

import './remove-user.scss';

const RemoveUser = ({ user, onConfirmClick, onCancelClick }) => (
  <section className="remove-user">
    <div className="remove-user__message">
      <p>
        Are you sure you want to remove <span>{user?.full_name ?? 'this user'}</span>?
      </p>
      <p>All of their data will be permanently deleted.</p>
    </div>
    <ButtonGroup>
      <Button label="Confirm" onClick={onConfirmClick} />
      <Button label="Cancel" onClick={onCancelClick} secondary />
    </ButtonGroup>
  </section>
);

export default RemoveUser;
