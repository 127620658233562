export const tableHeaders = [
  { Header: '', accessor: 'image', disableSortBy: true },
  { Header: 'Event Name', accessor: 'name' },
  { Header: 'Competition Name', accessor: 'competitionName' },
  { Header: 'Start Date', accessor: 'startDate' },
  { Header: 'End Date', accessor: 'endDate' },
  { Header: 'Place', accessor: 'venue' },
  { Header: 'Status', accessor: 'status' },
  { Header: '', accessor: 'action', disableSortBy: true }
];

export const tableModifiers = {
  action: {
    actions: true
  }
};

export const getEventInitials = ({ name }) => {
  return name
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('');
};
