import React from 'react';
import { arrayOf, func, node, oneOfType, string } from 'prop-types';

import { ClearFilters, FilterDropdown, SearchInput } from '@neslotech/eventhub-ui-kit';
import { noOp } from '@neslotech/utils';

import { useFilters } from '../../../hooks/useFilters';
import FilterProvider from '../../../providers/Filter.provider';

import './filter-layout.scss';

const FiltersSection = ({ actions, menuItems, searchPlaceholder }) => {
  const { setSearch, setFilters, search, filtering, onClearFilters, filters } = useFilters();

  return (
    <header className="filter-layout__header">
      <section className="filter-layout__filters">
        <SearchInput placeholder={searchPlaceholder} onChange={setSearch} value={search} />
        {menuItems && (
          <FilterDropdown setFilters={setFilters} menuItems={menuItems} filters={filters} />
        )}
        {filtering && <ClearFilters onClick={onClearFilters} />}
      </section>
      <div className="filter-layout__actions">{actions}</div>
    </header>
  );
};

const FilterLayout = ({ actions, menuItems, searchPlaceholder, children }) => (
  <section className="filter-layout">
    <FilterProvider>
      <FiltersSection
        menuItems={menuItems}
        actions={actions}
        searchPlaceholder={searchPlaceholder}
      />
      <main className="filter-layout__content">{children}</main>
    </FilterProvider>
  </section>
);

FilterLayout.defaultProps = {
  filters: noOp,
  searchPlaceholder: 'Search...'
};

FilterLayout.propTypes = {
  actions: oneOfType([arrayOf(node), node]),
  filters: func,
  searchPlaceholder: string
};

export default FilterLayout;
