import { SET_COMPETITION, SET_COMPETITIONS } from '../../actions/competition/competition.actions';

export const initialState = {
  competitions: [],
  competition: undefined
};

export const competitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPETITIONS:
      const { competitions } = action;
      return {
        competitions
      };
    case SET_COMPETITION:
      const { competition } = action;
      return {
        competition
      };
    default:
      return state;
  }
};
