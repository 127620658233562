import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { loadOrganisation } from '../../../../actions/organisation/organisation.actions';
import { acceptVerification } from '../../../../actions/verification/verification.actions';

import OrganisationDetails from '../../../../components/organisations/details/OrganisationDetails';
import AdminLayout from '../../../../components/layout/admin/AdminLayout';

import RejectVerificationContainer from '../reject/RejectVerification.container';
import RequestChangeVerificationContainer from '../request-change/RequestChangeVerification.container';

const VerificationContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const organisation = useSelector(({ organisation_store }) => organisation_store.organisation);
  const stateLoading = useSelector(({ organisation_store }) => organisation_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);
  const [rejectedOrganisation, setRejectedOrganisation] = useState();
  const [requestedChangeOrganisation, setRequestedChangeOrganisation] = useState();

  useEffect(() => {
    dispatch(loadOrganisation(id, () => setLoading(false)));
  }, [dispatch, id]);

  const handleAccept = () => {
    dispatch(acceptVerification(organisation.id, navigate));
  };

  return (
    <>
      <AdminLayout gutterless>
        <PageLayout title="Organisation Details" back fixed>
          {loading && <Loader filled />}
          {!loading && (
            <OrganisationDetails
              organisation={organisation}
              onAccept={handleAccept}
              onReject={() => setRejectedOrganisation(organisation)}
              onRequestChange={() => setRequestedChangeOrganisation(organisation)}
            />
          )}
        </PageLayout>
      </AdminLayout>
      <RejectVerificationContainer
        organisation={organisation}
        show={rejectedOrganisation}
        setShowModal={setRejectedOrganisation}
      />
      <RequestChangeVerificationContainer
        organisation={organisation}
        show={requestedChangeOrganisation}
        setShowModal={setRequestedChangeOrganisation}
      />
    </>
  );
};

export default VerificationContainer;
