export const LOAD_VERIFICATIONS = '[VERIFICATION_STORE] Load verifications';
export const SET_VERIFICATIONS = '[VERIFICATION_STORE] Set verifications';
export const loadVerifications = (onComplete) => ({
  type: LOAD_VERIFICATIONS,
  onComplete
});

export const ACCEPT_VERIFICATION = '[VERIFICATION_STORE] Accept verification';
export const acceptVerification = (id, navigate) => ({
  type: ACCEPT_VERIFICATION,
  id,
  navigate
});

export const REJECT_VERIFICATION = '[VERIFICATION_STORE] Reject verification';
export const rejectVerification = (id, payload, navigate) => ({
  type: REJECT_VERIFICATION,
  id,
  payload,
  navigate
});

export const REQUEST_CHANGE_VERIFICATION = '[VERIFICATION_STORE] Request change verification';
export const requestChangeVerification = (id, payload, navigate) => ({
  type: REQUEST_CHANGE_VERIFICATION,
  id,
  payload,
  navigate
});
