export const LOAD_PROFILE = '[PROFILE_STORE] Load my profile';
export const SET_PROFILE = '[PROFILE_STORE] Set my profile';
export const loadProfile = (onComplete) => ({
  type: LOAD_PROFILE,
  onComplete
});

export const UPDATE_PROFILE = '[PROFILE_STORE] Update my profile';
export const updateProfile = (profile) => ({
  type: UPDATE_PROFILE,
  profile
});

export const LOAD_DASHBOARD = '[PROFILE_STORE] Load dashboard data';
export const SET_DASHBOARD = '[PROFILE_STORE] Set dashboard data';
export const loadDashboardData = (onComplete) => ({
  type: LOAD_DASHBOARD,
  onComplete
});
