import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { loadCompetition } from '../../../../actions/competition/competition.actions';

import AdminLayout from '../../../../components/layout/admin/AdminLayout';
import CompetitionDetails from '../../../../components/competitions/details/CompetitionDetails';
import RemoveCompetitionContainer from '../remove/RemoveCompetition.container';

const CompetitionContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const competition = useSelector(({ competition_store }) => competition_store.competition);
  const stateLoading = useSelector(({ competition_store }) => competition_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);
  const [competitionToDelete, setCompetitionToDelete] = useState(undefined);

  useEffect(() => {
    dispatch(loadCompetition(id, () => setLoading(false)));
  }, [dispatch, id]);

  return (
    <>
      <AdminLayout gutterless>
        <PageLayout title="View Competition Details" back fixed>
          {loading && <Loader filled />}
          {!loading && (
            <CompetitionDetails
              competition={competition}
              onDelete={() => setCompetitionToDelete(competition)}
            />
          )}
        </PageLayout>
      </AdminLayout>
      <RemoveCompetitionContainer
        competition={competitionToDelete}
        show={competitionToDelete}
        setShowModal={setCompetitionToDelete}
      />
    </>
  );
};

export default CompetitionContainer;
